 /*local */ 
// export const apiUrl      = "http://localhost/lux/api/";
// export const baseUrl      = "http://localhost/lux";
// export const mediaUrl      = "http://localhost/lux/media/";
// export const mediaUrlImage      = "http://localhost/lux/media/product";
// export const sampleFileUrl      = "http://localhost/lux/media/sample_file/";
// export const cmscontentURL       = "http://localhost/lux/media/cmscontent";
// export const categoryURL       = "http://localhost/lux/media/category";
// export const productURL       = "http://localhost/lux/media/product";
// export const tapURL       = "http://localhost/lux/media/tabicons/";
// export const amenityURL       = "http://localhost/lux/media/amenities"; 

// /*Live */


export const apiUrl           = "https://admin-f.uxt.design/formbooking/api2/";
export const mediaUrl           = "https://admin-f.uxt.design/formbooking/media/";
export const baseUrl          = "https://admin-f.uxt.design/formbooking";
export const carImageUrl      = "https://admin-f.uxt.design/formbooking/media/cars";
export const licenseURL       = "https://admin-f.uxt.design/formbooking/media/driverlicense";
export const insuranceURL     = "https://admin-f.uxt.design/formbooking/media/driverinsurance";
export const profileURL       = "https://admin-f.uxt.design/formbooking/media/driverprofile";
export const drivercarURL     = "https://admin-f.uxt.design/formbooking/media/drivercarimage";
export const sampleFileUrl    = "https://admin-f.uxt.design/formbooking/media/sample_file/";
export const cmscontentURL    = "https://admin-f.uxt.design/formbooking/media/cmscontent";
export const categoryURL      = "https://admin-f.uxt.design/formbooking/media/category";
export const productURL       = "https://admin-f.uxt.design/formbooking/media/product";
export const formentriesURL   = "https://admin-f.uxt.design/formbooking/media/formentries";
export const invoiceURL       = "https://admin-f.uxt.design/formbooking/media/order_invoice";
export const cmsURL       = "https://admin-f.uxt.design/formbooking/media/cmsmanagement";