import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Axios from 'axios';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';

import { apiUrl, carImageUrl, currency, dummycarUrl,categoryURL} from'../Config/Config';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";

	
import { useParams, useNavigate } from 'react-router-dom';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const categoryid = (typeof this.props.params.ID === 'undefined') ? '' : this.props.params.ID;
    this.state = {      
      categoryid: categoryid,
      categorylistview:'',
    };

    var qs = require('qs');
    var postObject = {             
    id   :categoryid
    };

    Axios.post(apiUrl+"category/view",qs.stringify(postObject)).then(res => {
      if(res.data.status === 'success'){
        console.log( res.data.categorylistview)
          this.setState({ categorylistview: res.data.categorylistview[0]});

      }
    }); 
   
  }

   
   componentDidMount() {
     document.title = PageTitle('Category View');
   }

   componentDidUpdate(prevProps,prevState){

   }
   componentWillReceiveProps(Props){
      if(Props.categorylistview!==this.state.categorylistview){
        if(Props.categorylistview[0].status == "success"){
          this.setState({categorylistview: Props.categorylistview[0].categorylistview[0]}) 
        }
      }
   }


  
  render() {


    let catImg = '';
  let preview = '';

      const {cat_image} = this.state.categorylistview;
      if (cat_image!== null && cat_image!== '') {
      catImg = categoryURL + "/" + cat_image;
    preview = <img className="img_class" style={{width:"250px",height:"200px"}} src={catImg} alt="" />;
  }
  const navigate = this.props.navigate
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="Category" />  
      
      <div className="content"> 
        <div className="content-wrapper">
            <div className="content-body">
            <div className="content-wrapper-before"></div>
            <div className="form-wrapper leaa-admin-head card">
                  <div className="back-btn-lux">
                  <a onClick={() => navigate(-1)}>Back</a>    
                </div> 
                <div className="title">
                  <h3>Category View</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Category Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.categorylistview.cat_name}</p>
                        </div>
                      </div>
                     {/* <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Short Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.categorylistview.short_name}</p>
                        </div>
                      </div>*/}
                      
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Description<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.categorylistview.cat_description}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Short Description<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.categorylistview.short_description}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Category Priority<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.categorylistview.cat_priority}</p>
                        </div>
                      </div>

                        <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Category Status<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.categorylistview.status}</p>
                        </div>
                      </div>
                      <div className="view-details">
                      </div>
                        <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Category Image<span></span> </b></p>
                            {preview}
                        </div>
                        {/*<div className="view-details-two">
                            {preview}
                        </div>*/}
                      </div>

                      </div>
                      </div>
               
                    
                     
                    
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}


export default withRouter(View);