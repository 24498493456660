import React, { Component } from 'react';
import { Link } from "react-router-dom";

import Pagination from "react-js-pagination";
import $ from 'jquery';
import axios from 'axios';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import { apiUrl, carImageUrl, currency, dummycarUrl,formentriesURL} from'../Config/Config';
import moment from 'moment';
import { useParams, useNavigate } from 'react-router-dom';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };

class View extends Component {
  
  constructor(props)
  {
    super(props); 
    
    const entriesid = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
    this.state = {      
      entriesid: entriesid,
      entriesview:'',
    };

    var qs = require('qs');
    var postObject = {             
    id   :entriesid
    };

      axios.post(apiUrl+"formbuilder/userformentryview",qs.stringify(postObject)).then(res => {
			if(res.data.status == 'success'){
				const formdata = res.data.entrieslist;
			
			this.setState({entriesview:res.data.entrieslist});
						
			}
			
		}); 
 
  }

   
   componentDidMount() {
     document.title = PageTitle('Entries View');
   }

   componentWillReceiveProps(Props){
      if(Props.entriesview!==this.state.entriesview){
        if(Props.entriesview[0].status == "success"){
          this.setState({entriesview: Props.entriesview[0].entrieslist}) 
        }
      }
   }
  
  formentries(){
    const entriesview = this.state.entriesview;
    if(entriesview!== undefined && entriesview!== null){
            if(Object.keys(entriesview).length > 0) {
              const Details = entriesview.entries.map((datas, index) =>{
                                return(
                                  <div className="details-view-full">
                                      <div className="details-view-left">
                                         {datas.name}<span>:</span>
                                      </div>
                                      <div className="details-view-right">
                                        <p>{decodeURIComponent(datas.value)}</p>
                                      </div>
                                      {

                                      }
                                  </div>
                                );
                              })
               return Details;
          }

 }                
}
  render() {
    const navigate = this.props.navigate;
    return (
      <div className="wrapper"> 
      <Header />

    <MenuSidebar currentpage="form-entries" />  
      <div className="content"> 
    <div className="content-wrapper">
    <div className="content-wrapper-before"></div>
    <div className="form-wrapper leaa-admin-head card">
            <div className="listing-header">
                <div className="back-btn-lux">
                  <a onClick={() => navigate(-1)}>Back</a>    
                </div> 
                      <div className="title">
                            <h4>Form Details</h4>
                      </div>
                      <div className="add_button">  
                          
                      </div>  
           </div>
           
            <div className="form-row">
              <div className="form-left form-entries">
                  <div className="form-entries-main">
                  <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Form name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.entriesview.form_name}</p>
                          </div>
                  </div>
                  <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Category<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.entriesview.category}</p>
                          </div>
                  </div>
          
                  <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Submitted by<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.entriesview.username?this.state.entriesview.username:'Guest User'}</p>
                          </div>
                  </div>
          
                  <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Submitted on<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{moment(this.state.entriesview.submitted_on).format('MM/DD/YYYY')}</p>
                          </div>
                  </div>
                   </div>
                  <div className="details-view entries-details-view">
                    <span><h3>Entries</h3></span>
                      {this.formentries()}
                 </div>
              </div>
              {this.state.entriesview.images!==''?<div className="form-right">
                  <div className="details-view entries-details-view">
                    <span><h3>Form Image:</h3></span>
                      <img src={formentriesURL+'/'+this.state.entriesview.images} />
                 </div>
              </div>:''}
            </div>
          </div>  
      </div>        
    </div>  
  </div>      
    );
  }
}


export default withRouter(View);