import React, { Component } from 'react';
import { Link } from "react-router-dom";

import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl,productURL} from'../Config/Config';

import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";

import { useParams, useNavigate } from 'react-router-dom'

import noimage from '../../common/images/no-image.png';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const productid = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;

    this.state = {      
      productid: productid,
      productlistview:'',
      imagelist:[],
    };

    var qs = require('qs');
    var postObject = {             
    id   :productid
    };

    axios.post(apiUrl+"product/view",qs.stringify(postObject)).then(res => {
      if(res.data.status === 'success'){
        console.log( res.data.colorlistview)
          this.setState({ productlistview: res.data.productlistview[0],paymenthistorylist:res.data.paymenthistoryview,total_amt: res.data.total_amt});

      }
    }); 
  }

   
   componentDidMount() {
     document.title = PageTitle('Product View');
   }

   componentDidUpdate(prevProps,prevState){

   }
   componentWillReceiveProps(Props){
      if(Props.productlistview!==this.state.productlistview){
        if(Props.productlistview[0].status == "success"){
          this.setState({productlistview: Props.productlistview[0].productlistview[0]}) 
        }
      }

      if(Props.productlistview!==this.state.productlistview){
        if(Props.productlistview[0].status == "success"){
          this.setState({imagelist: Props.productlistview[0].imagelist}) 
        }
      }else{
              this.setState({ imagelist : ''})
            }

   }


   parseSerializedData(serializedData) {
    try {
      // Custom function to parse serialized data
      const regex = /s:\d+:"(.*?)";/;
      const match = serializedData.match(regex);
      if (match) {
        return match[1];  // Extracts "2111251637854748applainces.png"
      }
      return null;
    } catch (error) {
      console.error('Failed to parse serialized data:', error);
      return null;
    }
  }
  render() {
    const navigate = this.props.navigate;
var lasturrl = window.location.href.substr(window.location.href.lastIndexOf('/') +1);

    let catImg = '';
  let preview = '';

      const {product_image} = this.state.productlistview;
      
      
      const {imagelist} = this.state;
     
      if ((product_image!== null) && (product_image!== '') && (this.state.imagelist =='')) {
      catImg = productURL + "/" + product_image;
       console.log("hiidfdfdf",catImg);
    preview = <img className="img_class" style={{width:"300px",height:"150px"}} src={catImg} alt="" />;
  }else{

      preview = this.state.imagelist.map((imagelist_fi, index) =>{
       catImg = productURL + "/" + imagelist_fi.image_product;
       console.log("hii",catImg);
          return(
               <>
              <div className="form-group" key={index+'imagelist'}>
            <img className="img_class" style={{width:"300px",height:"150px"}} src={catImg} alt="" />
            </div>
            <br></br>
            </>

            );
     });

  }
    return (
      <div className="wrapper"> 
 
    <Header />
    {(lasturrl ==='outstock')?
    <>
     <MenuSidebar currentpage="product-outstock" />
     </>:<>
     <MenuSidebar currentpage="product" /> 
    </>
  }
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
            <div className="content-body">
            <div className="form-wrapper leaa-admin-head card">
                <div className="back-btn-lux">
                  <a onClick={() => navigate(-1)}>Back</a>    
                </div> 
                <div className="title">
                  <h3>Product View</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Product Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.productlistview.product_name}</p>
                        </div>
                      </div>
                      {/*<div className="view-details">
                        <div className="view-details-one">
                            <p><b>Short Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.productlistview.product_name}</p>
                        </div>
                      </div>*/}
                      
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Description<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.productlistview.product_description}</p>
                        </div>
                      </div>
                       <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Short Description<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.productlistview.short_description}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Product Color<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.productlistview.product_color}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Product Quantity<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.productlistview.product_quantity}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Product Price<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.productlistview.price}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Category<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.productlistview.cat_name}</p>
                        </div>
                      </div>
                     
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Product Priority<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.productlistview.product_priority}</p>
                        </div>
                      </div>

                        <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Product Status<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.productlistview.status}</p>
                        </div>
                      </div>
                      <div className="view-details">
                      </div>
                        <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Product Image<span></span> </b></p>
                            {preview}
                        </div>
                        {/*<div className="view-details-two">
                            {preview}
                        </div>*/}
                      </div>

                      </div>
                      </div>
               
                    
                     
                    
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}




export default withRouter(View);