const year_list = () => {

    let years = [];  
    let start_yr = 1950;
    let end_yr = new Date().getFullYear();
    let diff = end_yr - start_yr;
    // console.log(end_yr, 'end_yr');
    // console.log(diff, 'diff');
    let i=0;
    for(i; i<=diff; i++){
        let opt = start_yr + i;
        years[i] = { value: opt, label: opt };
    }
    return years;
}
export const lang = {

    common: {
        title:'FormBooking',
        status_option:[
            { value: 'active', label: 'Active' },
            { value: 'inactive', label: 'Inactive' },
        ],
        gender_option: [
            { value: 'Male', label: 'Male' },
            { value: 'Female', label: 'Female' }
        ],
        login_type :[
            { value: 'User', label: 'User' },
            { value: 'Company', label: 'Company' },
            { value: 'Stores', label: 'Stores' },
          
        ],
		/* image_size_option: [
            { value: '250x250', label: '250x250' },
			{ value: '350x350', label: '350x350' },
            { value: '520x520', label: '520X520' }
        ],*/
        image_size_option: [
            { value: '1031x400', label: '1031x400 (W X H)' },
            { value: '250x180', label: '250x180 (W X H)' },
			{ value: '200x150', label: '200x150 (W X H)' },
            { value: '180x120', label: '180x120 (W X H)' }
        ],
        field_options_ads :[
            { value: 'Appliances', label: 'Appliances' },
            { value: 'store', label: 'Store' },
            { value: 'Gift Products', label: 'Gift product' },
            { value: 'dna', label: 'DNA Tests' },
        ],
        advertisement_linktype :[
            { value: 'internal', label: 'Internal Link' },
            { value: 'external', label: 'External Link' },
            { value: 'offerpage', label: 'Offer Page' },
           
        ],
        field_options_dashboard :[
            { value: 'favorites', label: 'Favorites' },
            { value: 'coupons', label: 'Coupons' },
            

            { value: 'orders', label: 'Orders' },
            { value: 'myorders', label: 'My Orders' },
            { value: 'reviews', label: 'Reviews' },
            { value: 'feedbacks', label: 'Feedbacks' },
            { value: 'myCart', label: 'My Cart' },
           

            { value: 'completedorders', label: 'Completed Orders' },
            { value: 'users', label: 'Users' },
            { value: 'pendingOrders', label: 'Pending Orders' },
            { value: 'accountmanagement', label: 'Accountmanagement' },
            { value: 'membership', label: 'Membership' },
            { value: 'monthlyearning', label: 'Monthly Earning' },
            { value: 'yearlyyearning', label: 'Yearly Earning' },
            { value: 'stores', label: 'Stores' },
            { value: 'deleteditems', label: ' Deleted Items'},
            { value: 'products', label: 'Products' },
            { value: 'outstock', label: 'Out Of Stocks' },

        ],
        pluging_width :[
            { value: '700', label: '700px' },
            { value: '600', label: '600px' }
        ],
        coming_source :[
            { value: '0', label: 'Select' },
            { value: 'friends', label: 'Friends' },
            { value: 'facebook', label: 'Facebook' },
            { value: 'instagram', label: 'Instagram' },
            { value: 'messaging apps', label: 'Messaging Apps' },
            { value: 'website', label: 'Website' },
            { value: 'flyers', label: 'Flyers' },
            { value: 'sms', label: 'Sms' },
            { value: 'newspaper', label: 'Newspapers' },
            { value: 'others', label: 'Others' }
        ],
         field_options :[
            { value: 'text', label: 'Text' },
            { value: 'email', label: 'Email' },
            { value: 'tel', label: 'Tel' },
            { value: 'textarea', label: 'Textarea' },
            { value: 'password', label: 'Password' },
            { value: 'select', label: 'Select' },
            { value: 'checkbox', label: 'Checkbox' },
            { value: 'radio', label: 'Radio' },
            { value: 'url', label: 'url' },
            { value: 'image', label: 'Image' },
        ],
        discount_option:[
            { value: 'percentage', label: 'Percentage' },
            { value: 'standard', label: 'Standard' },
        ],
        year_option: year_list(),
    },
    Capitalize(str){
        return str.charAt(0).toUpperCase() + str.slice(1);
        }
};


