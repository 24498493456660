import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";


/* import { GET_VIEWUSERLIST } from '../../actions'; */
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const userid = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
    console.log(userid,'this is userid')
    this.state = {      
      userid: userid,
      userlistview:'',
      familtlistview:'',
    };

    axios.get(apiUrl+"user/listdetail?id="+userid).then(res => {
			if(res.data.status == 'success'){
				const formdata = res.data.userlist;
			
			this.setState({userlistview:res.data.userlist});
						
			}
			
		}); 
  }

   
   componentDidMount() {
     document.title = PageTitle('User View');
   }

   componentDidUpdate(prevProps,prevState){

   }
   componentWillReceiveProps(Props){
   
    
   }
   
  render() {
      console.log(this.state.userlistview,'thsi si sworking for view page')
    const navigate = this.props.navigate;
    if((this.state.userlistview.subscribed_plan_name!='')&&(this.state.userlistview.subscribed_plan_name!=null)){
      if(this.state.userlistview.subscribed_plan_name == "Free"){

       var plan_paid_amount = "$"+0;
      }else{
         var plan_paid_amount = "$"+this.state.userlistview.plan_paid_amount;
      }
  }else{
   var plan_paid_amount = "N/A";
  }

  if((this.state.userlistview.subscribed_plan_name!='')&&(this.state.userlistview.subscribed_plan_name!=null)){
      var subscribed_plan_name = this.state.userlistview.subscribed_plan_name;
  }else{
   var subscribed_plan_name = "N/A";
  }


  if((this.state.userlistview.transactionId!='')&&(this.state.userlistview.transactionId!=null)){
      var transactionId = this.state.userlistview.transactionId;
  }else{
   var transactionId = "N/A";
  }

  var mobileno = ((this.state.userlistview.mobileno === null)||((this.state.userlistview.mobileno === ''))) ?  this.state.userlistview.mobileno = 'N/A' : this.state.userlistview.mobileno;

  var lastname = ((this.state.userlistview.lastname === null)||(this.state.userlistview.lastname === "")) ?  this.state.userlistview.lastname = 'N/A' : this.state.userlistview.lastname;

  return (
    <div className="wrapper"> 

  <Header />
  <MenuSidebar currentpage="ongoingride" />  
    
    <div className="content"> 
      <div className="content-wrapper">
          <div className="content-body">
          <div class="content-wrapper-before"></div>
             <div className="form-wrapper leaa-admin-head card">
             <div className="back-btn-lux">
                  <a onClick={() => navigate(-1)}>Back</a>    
                </div> 
              <div className="title">
                <h3>User Details</h3>
              </div>
              <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>User Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.userlistview.username}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>First Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.userlistview.firstname}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Last Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{lastname}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Email<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.userlistview.email}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Mobile number<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{mobileno}</p>
                        </div>
                        
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>User Type<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.userlistview.user_type}</p>
                        </div>
                      </div>

                     <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Plan type<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{subscribed_plan_name}</p>
                        </div>
                        
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Paid Plan amount<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{plan_paid_amount}</p>
                        </div>
                      </div>

                       <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Transaction ID<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{transactionId}</p>
                        </div>
                        </div>


                  </div>
                </div>
                  <div className="map-wrapper">                      
                   <div id="map"></div>
                   <div className="mapbox-gl-marker"></div>
                  </div>
            </div>            
          
        </div>
       </div> 
      </div>        
  </div>
  );
  }
}
export default withRouter(View);
