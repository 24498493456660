import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl} from'../Config/Config';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";

const initialState = {
			username:'',
			firstname:'',
			lastname:'',
			email:'',
			phoneno:'',
			status:'',
			user_password: '',
            user_confirm_password: '',
            new_pass_type: 'password',
            confrim_pass_type: 'password',
            pass_show_hide_icon: 'shown',
            confirm_show_hide_icon: 'shown',
            selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			disablepasssword:true,
			domainlist:[],
			selectedDomainOption:'',
			selectedDomainvalue:'',
			domainlistlength:'',
			maxCharacterLimit : 20,
			selectedYear:'',
			selectedYearvalue:'',
			street:'',
			city:'',
			state:'',
			country:'',
			postalcode:'',
			amenitynamelist:[],
			selectedamenityid:''

		};
		const withRouter = WrappedComponent => props => {
			const params = useParams();
			const  navigate = useNavigate();
			// etc... other react-router-dom v6 hooks
		  
			return (
			  <WrappedComponent
				{...props}
				params={params}
				navigate ={navigate}
				// etc...
			  />
			);
		  };
class Add extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state =  { ...initialState }
		
	    this.handleInputChange = this.handleInputChange.bind(this);
	   this.new_pass_show_hide = this.new_pass_show_hide.bind(this);
       this.confim_pass_show_hide = this.confim_pass_show_hide.bind(this);
       this.handleChange = this.handleChange.bind(this);

    }
	 handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
      if(name == 'password'){
      	this.setState({disablepasssword: false})
      }
    }
    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	handleYearChange = selOption => {
        this.setState({selectedYear:selOption})
			this.setState({  selectedYearvalue : selOption.value});
    };
	handledomainChange = selectedDomainOption => {
		
		this.setState({selectedDomainOption:selectedDomainOption})
		this.setState({  selectedDomainvalue : selectedDomainOption.value});
	
}
 isUSZipCode(zipCode) {
	// Regular expression pattern for validating US ZIP codes
	const zipCodePattern = /^\d{5}(-\d{4})?$/;
	return zipCodePattern.test(zipCode);
  }
	componentDidMount() {
		var  selecteddomainOption ='';
		var selectedDomainvalue ='';
		document.title = PageTitle('Add rehab');
		if(localStorage.getItem('admin_id') === null){
		this.props.history.push('/');
		}
		let domainlist = [];
		axios.post(apiUrl+"user/domainlist").then(res => {
			if(res.data.status == "success"){
				let i=0;
				let domainlists = res.data.domainlist;
		
				for(i; i<domainlists.length; i++){
					
					domainlist[i] = { value: domainlists[i]['domain_id'], label: domainlists[i]['domain_name'] };
				}
				
				if(res.data.domainlist.length==1){
				let	j=0;
					for(j; j<res.data.domainlist.length; j++){
						
					 selecteddomainOption = { value:domainlists[j]['domain_id'], label: domainlists[j]['domain_name'] };
				    selectedDomainvalue = domainlists[j]['domain_id'];	
				}
					
					this.setState({selectedDomainOption:selecteddomainOption})
					this.setState({selectedDomainvalue:selectedDomainvalue})
				}

			}       
		});
		
		var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl+'rehab/getamenitynamelist?admin_id='+admin_id)
			.then(res => {
				if(res.data.status == 'success'){
				
					this.setState({
						amenitynamelist:res.data.amenitynamelist
					});
				
				}else{
				}
			});
			
		

    }
	new_pass_show_hide = () => {
    this.setState({
         new_pass_type: this.state.new_pass_type === 'input' ? 'password' : 'input',
         pass_show_hide_icon: this.state.new_pass_type === 'input' ? 'shown' : 'hiden'
        });
    }
    
    confim_pass_show_hide = () => {
    this.setState({
         confrim_pass_type: this.state.confrim_pass_type === 'input' ? 'password' : 'input',
         confirm_show_hide_icon: this.state.confrim_pass_type === 'input' ? 'shown' : 'hiden'
        });
    } 

	
	 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				var qs = require('qs');
				const formPayload = this.state;
			    var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				const selectedamenityid = formPayload.selectedamenityid;
	var elements=[];
	let catList  = ''
	if(selectedamenityid!=='' && typeof selectedamenityid!==undefined && selectedamenityid!==null) {
		catList = selectedamenityid.map((number) =>
		elements.push(number.value)
	);
	}
	const selectedcategorys=elements.toString();
				var postObject = {
			        admin_id : localStorage.getItem("admin_id"),
					userid       : formPayload.userid,
					domain_id       : formPayload.selectedDomainvalue,
					name    : formPayload.firstname,
					startedYear    : formPayload.selectedYearvalue,
					rehabname     : formPayload.rehabname,
					email        : formPayload.email,
					phoneno      : formPayload.phoneno,
					street      : formPayload.street,
					city      : formPayload.city,
					state      : formPayload.state,
					country      : formPayload.country,
					postalcode      : formPayload.postalcode,
					amenityids       :  selectedcategorys,
					status       :  status,
				};
				console.log('domain_id',postObject);
				axios.post(apiUrl+"rehab/add",qs.stringify(postObject)).then(res => {
				 
					if(res.data.status === 'success'){
					   $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						   this.setState({
							username:'',			
							firstname:'',
							lastname:'',
							email:'',
							phoneno:'',
							password: '',
							confirm_password:''
						   });
						   setTimeout(
						   function() {
							
							   $('.success_message').html('');
									this.props.navigate('/rehabs');
						   }
						   .bind(this),
						   3000
						   );
					   }else{
						this.setState({Loading:false});
						   $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						   setTimeout(
						   function() {
							   $('.success_message').html('');
						   }
						   .bind(this),
						   3000
						   );
					   }
				  }); 
			}
	}

	validateForm() {
		const {firstname, rehabname,email, phoneno,selectedDomainvalue,postalcode} = this.state;
		let errors = 0;
		const nameRegex = /^[A-Za-z\s.]+$/;

		

		if (!selectedDomainvalue) {
		 	errors++;
		 	$('.errorassign_domain').html('<span class="errorspan">Please select the field</span>');
		}else if(selectedDomainvalue){
		 	$('.errorassign_domain').html('');
		 }
		
		if (!firstname) {
			errors++;
			$('.errorfirstname').html('<span class="errorspan">Please fill the field</span>');
		}else if(firstname){
			if (!nameRegex.test(firstname)) {
				errors++;
			$('.errorfirstname').html('<span class="errorspan">Please enter the valid name</span>');
			}else{
				$('.errorfirstname').html('');
			}
			
		}
		if (!rehabname) {
			errors++;
			$('.errorrehabname').html('<span class="errorspan">Please fill the field</span>');
		}else{
				$('.errorrehabname').html('');
		}
		
		if (!email) {
			errors++;
			
			$('.erroremail').html('<span class="errorspan">Email is required</span>');
			}else if(email){
			var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
			if (reg.test(email)) 
			{
			  $('.erroremail').html('');
			}else{
			  errors++;
			  
			$('.erroremail').html('<span class="errorspan">Please enter valid email address</span>');
			}
			  
			
			}
		

		if (!phoneno) {
			errors++;
			
			$('.errorphoneno').html('<span class="errorspan">Please fill the field</span>');
		  }else if(phoneno){	
			 var pattern = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
			 var indiapattern =/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
				if (pattern.test(phoneno)) {
				$('.errorphoneno').html('');
				}else if(indiapattern.test(phoneno)){
				$('.errorphoneno').html('');
				}else{
					 errors++;
				
					 $('.errorphoneno').html('<span class="errorspan">Please enter valid contact</span>');
				   }
				}

		if(postalcode){
			if(this.isUSZipCode(postalcode)){
				$('.errorpostalcode').html('');
			}else{
				errors++;
				$('.errorpostalcode').html('Please enter valid postal code');
			}
		}
		

		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

		

    componentWillReceiveProps(Props){

    		if(Props.useradd !== this.props.useradd){
    		if(Object.keys(Props.useradd).length > 0){
				this.setState({Loading:false});

				if(Props.useradd[0].status === "success"){

				 $('.success_message').html('<div class="status_sucess"><h3>'+Props.useradd[0].message+'</h3></div>');
				scrollToTop();
						setTimeout(
						function() {
							$('.success_message').html(this.props.history.push('/rehabs'));
						}
						.bind(this),
						3000
						);
					this.setState({username:'',firstname:'',lastname:'',email:'',phoneno:'',user_password:'',user_confirm_password:''})
					}else if(Props.useradd[0].status === "erroremail"){

					 $('.erroremail').html('<span class="errorspan">'+Props.useradd[0].message+'</span>');
				     	scrollToTop();
							setTimeout(
							function() {
								$('.erroremail').html('');
							}
							.bind(this),
							3000
							);
					}else if(Props.useradd[0].status === "errorphone"){

					 $('.errorphoneno').html('<span class="errorspan">'+Props.useradd[0].message+'</span>');
				     	scrollToTop();
							setTimeout(
							function() {
								$('.errorphoneno').html('');
							}
							.bind(this),
							3000
							);
					}else if(Props.useradd[0].status === "errorusername"){

					 $('.errorusername').html('<span class="errorspan">'+Props.useradd[0].message+'</span>');
				     	scrollToTop();
							setTimeout(
							function() {
								$('.errorusername').html('');
							}
							.bind(this),
							3000
							);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+Props.useradd[0].message+'</h3></div>');
						scrollToTop();
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}

    		}
    	}
    }
	handleClickSelecet = (event) => {
		const selectedIndex = event.target.options.selectedIndex;
		var key = event.target.options[selectedIndex].getAttribute('data-key');
		this.setState({
				domain_id: key,
				
		})


	}
	handleProductCategoryChange = (event) => {
		console.log(event,'event')
		this.setState({selectedamenityid : event}, function() {
		   const user_list = this.state.selectedamenityid;
		 
		});
	}
	
  render() {
  	
  	const {selectedYear,selectedOption,selectedDomainOption} = this.state;

console.log(selectedDomainOption,"selectedDomainOption")
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="packages" />  

	
	<div className="content">	
		<div className="content-wrapper">
        <div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">

			<div className="success_message"></div>
			<div className="title">
				<h4>Add Rehab</h4>
			</div>
		<form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
			
				<div className="form-group">
					<label>Name<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="firstname" onChange={this.handleInputChange} className="form-control" value={this.state.firstname} />
					<div className="errorfirstname"></div>
				</div>
				<div className="form-group">
					<label>Contact<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="phoneno" onChange={this.handleInputChange} className="form-control" value={this.state.phoneno} />
					<div className="errorphoneno"></div>
				</div>
				
				<div className="form-group">
					<label>Year Started</label>
				    
					<Select 
							value={selectedYear?selectedYear:''}
								options={lang.common.year_option} 
								onChange={this.handleYearChange.bind(this)}
								/>
					<div className="erroryear_of_started"></div>
				</div> 
				<div className="default-font">
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} >*</span></label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
				</div>
				</div>
				</div>
			<div className="form-right">
			
				<div className="form-group">
					<label>Rehab Name<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="rehabname" onChange={this.handleInputChange} className="form-control" value={this.state.rehabname} />
					<div className="errorrehabname"></div>
				</div>
				<div className="form-group">
					<label>Email<span class="required" style={{ color: "red" }} >*</span></label>
						<input type="text" name="email" onChange={this.handleInputChange} className="form-control" value={this.state.email} autoComplete="off" />
					<div className="erroremail"></div>
				</div>

				<div className="form-group">	
					<label>Amenities </label>
					<Multiselect
					displayValue="label"
					onKeyPressFn={function noRefCheck(){}}
					onRemove={this.handleProductCategoryChange}
					onSearch={function noRefCheck(){}}
					onSelect={this.handleProductCategoryChange}
					value={this.state.selectedcategoryid}
					options={this.state.amenitynamelist}
					/>
	
					<div className="errorproductcategory"></div>
				 </div>
				
	                    
						<div className="form-group">					
					<label>Assign Domain<span class="required" style={{ color: "red" }} >* </span></label>
						   <Select 
						   value={selectedDomainOption?selectedDomainOption:""}
                            options={lang.common.domainList_option} 
                            onChange={this.handledomainChange}
                            />
							<div className="errorassign_domain"></div>
	           </div> 
			  
				 
				</div>
			</div>
			<div className="title">
					<h4> Address</h4>
			</div>

			<div className="form-row">
				<div className="form-left">
	
				<div className="form-group">
					<label>Street</label>
				    <input type="text" name="street" onChange={this.handleInputChange} className="form-control" value={this.state.street} />
					<div className="errorstreet"></div>
				</div>
				<div className="form-group">
						<label>State</label>
						<input type="text" name="state" onChange={this.handleInputChange} className="form-control" value={this.state.state} />
						<div className="errorstate"></div>
					</div>
				
				  
				<div className="form-group">
					<label>Postal Code</label>
				    <input type="text" name="postalcode" onChange={this.handleInputChange} className="form-control" value={this.state.postalcode} />
					<div className="errorpostalcode"></div>
				</div>             
				</div>


				<div className="form-right">
				<div className="form-group">
					<label>City</label>
				    <input type="text" name="city" onChange={this.handleInputChange} className="form-control" value={this.state.city} />
					<div className="errorcity"></div>
				</div>
				<div className="form-group">
					<label>Country</label>
				    <input type="text" name="country" onChange={this.handleInputChange} className="form-control" value={this.state.country} />
					<div className="errorcountry"></div>
				</div> 
					
				</div>

			</div>
			<div className="btn-group export">	
				   <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default withRouter(Add);