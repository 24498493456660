import React, { Component } from 'react';
// import { withRouter } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl,categoryURL} from'../Config/Config';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { useParams, useNavigate } from 'react-router-dom';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
	
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Add extends Component {

	constructor(props)
	{
		// CheckAuth();
		super();	
		this.state = {
			name:'',
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'active',
			displayColorPicker: false,
			color: {
			r: '241',
			g: '112',
			b: '19',
			a: '1',
			}
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onChangeHandler1 = this.onChangeHandler1.bind(this);

	
    }
	  
	handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
   };
   onChangeHandler=event=>{
    let reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {		
		
      this.setState({
        cat_image_name: file.name,
        cat_image_preview: reader.result,
        image: file
      });
	 
		
    };

    reader.readAsDataURL(file);
  }

  onChangeHandler1=event=>{
    let reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {		
		
      this.setState({
        cat_image_name1: file.name,
        cat_image_preview1: reader.result,
        image1: file
      });
	 
		
    };

    reader.readAsDataURL(file);
  }

  onChangeHandler2=event=>{
    let reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {		
		
      this.setState({
        cat_image_name2: file.name,
        cat_image_preview2: reader.result,
        image2: file
      });
	 
		
    };

    reader.readAsDataURL(file);
  }

   handleInputChange(event) {

	const {name, value} = event.target;      
	this.setState({
		[name]: value
	  });

	if(name === 'cat_trending'){
		this.setState({cat_trending_checked: !this.state.cat_trending_checked});
	}
	  
  }
  handleClick = () => {
	this.setState({displayColorPicker: !this.state.displayColorPicker })
	};
  handleClose = () => {
	this.setState({ displayColorPicker: false })
	};

	handleChangecolor = (color) => {
	this.setState({hexColor : color.hex})
	this.setState({ color: color.rgb })
	};


	
	handleFormSubmit = () => {
		if(this.validateForm()){
			this.setState({Loading:true});
			 const config = {
				headers: {
				  'Content-Type': 'multipart/form-data'
				}
			  };
			const formPayload = this.state;
			var qs = require('qs');
			var status = '';
			if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
			status =formPayload.selectedOption.value;
			}else{
			status = formPayload.selectedvalue;
			}
			var cat_trending = ''
			if(this.state.cat_trending_checked === true){
				 cat_trending = 1;
			}else{
				 cat_trending = 0;
			}


			var postObject = {
				admin_id : localStorage.getItem("admin_id"),
				domain_id : formPayload.domain_id,
				cat_name: formPayload.cat_name,
				short_name:formPayload.short_name,
				cat_priority: formPayload.cat_priority,
				cat_trending: cat_trending,
				cat_description: formPayload.description,
				short_description:formPayload.short_description,	
				category_bg:formPayload.category_bg,					
				cat_image:formPayload.image,
				cat_image_white:formPayload.image1,
				cat_image_yellow:formPayload.image2,
				cat_slug: formPayload.cat_slug,
				status:  status,
				form_id:formPayload.form_id,
			};

			let formData = new FormData();
			for(let k in postObject) {
			formData.append(k, postObject[k]);
			}		       
			axios.post(apiUrl+"category/add",formData,config).then(res => {
				if(res.data.status === "success"){
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
								setTimeout(
								function() {
									this.props.navigate('/category');
									// $('.success_message').html(this.props.history.push('/color'));
								}
								.bind(this),
								3000
								);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}
				});
			//this.props.getAddCategory(formData,config);
			//this.props.getAddCategory(qs.stringify(postObject));
		}
}
 
validateForm() {
	const {cat_name,cat_slug,short_name,cat_priority,description,short_description,image,image1,image2,status} = this.state;
	let errors = {};
	  let formIsValid = true;

	if (!cat_name) {
		formIsValid = false;
		$('.errorcat_name').html('<span class="errorspan">Please fill the field</span>');
	}else if(cat_name){
		$('.errorcat_name').html('');
	}


 

	/*if (!short_name) {
		$('.errorshort_name').html('<span class="errorspan">Please select the field</span>');
	}else if(short_name){
		$('.errorshort_name').html('');
	}*/
	

	if (!cat_priority) {
		formIsValid = false;
		$('.errorcat_priority').html('<span class="errorspan">Please fill the field</span>');
	}else if(cat_priority){
		$('.errorcat_priority').html('');
	}

	if (!description) {
		formIsValid = false;
		$('.errordescription').html('<span class="errorspan">Please fill the field</span>');
	}else if(description){
		$('.errordescription').html('');
	}

	if (!short_description) {
		formIsValid = false;
		$('.errorshort_description').html('<span class="errorspan">Please fill the field</span>');
	}else if(short_description){
		$('.errorshort_description').html('');
	}

	if (image=='') {
		formIsValid = false;
		$('.errorimage').html('<span class="errorspan">Please fill the field</span>');
	}else if(image){
		$('.errorimage').html('');
	}

	if (image1=='') {
		formIsValid = false;
		$('.errorimage1').html('<span class="errorspan">Please fill the field</span>');
	}else if(image1){
		$('.errorimage1').html('');
	}

	if (image2=='') {
		formIsValid = false;
		$('.errorimage2').html('<span class="errorspan">Please fill the field</span>');
	}else if(image1){
		$('.errorimage2').html('');
	}

	if(!cat_slug){
		formIsValid = false;
		$('.errorcat_slug').html('<span class="errorspan">Please fill the field</span>');
	}else if(image){
		$('.errorcat_slug').html('');
	}

	this.setState({
		errors: errors
	});
	return formIsValid;
}

	render() {
		const navigate = this.props.navigate;
		let preview = '';
		const {selectedOption,formbuildernamelist,cat_image_preview, cat_image_preview1, cat_image_preview2} = this.state;
	
		var current = this;
		
		return (
		  <div className="wrapper"> 
	 
		  <Header />
		  <MenuSidebar currentpage="Category" />  
	
		<div id="location-error"></div>
		<div className="content">	
			<div className="content-wrapper">
			<div className="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
				<div className="success_message"></div>
				<div className="back-btn-lux">
                  <a onClick={() => navigate(-1)}>Back</a>    
        			</div> 
				<div className="title">
					<h4>Add Category</h4>				
				</div>
				 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
				<div className="form-row">
				<div className="form-left">
					<div className="form-group">
						<label>Category Name<span class="required" style={{ color: "red" }} > * </span> : </label>
						<input type="text" name="cat_name" onChange={this.handleInputChange} className="form-control" value={this.state.cat_name} />
						<div className="errorcat_name"></div>
					</div>
	
				<div className="form-group">
						<label>Category Slug<span class="required" style={{ color: "red" }} > * </span> : </label>
						<input type="text" name="cat_slug" onChange={this.handleInputChange} className="form-control" value={this.state.cat_slug} />
						<div className="errorcat_slug"></div>
					</div>
					
				   <div className="form-group">					
						<label>Description:</label>
							<textarea type="text" name="description" onChange={this.handleInputChange} className="form-control" value={this.state.description}  />
						<div className="errordescription"></div>
					</div>
	
	
					<div className="form-group">
						
						<input onChange={this.handleInputChange} type="checkbox"  name="cat_trending" checked={this.state.cat_trending_checked}/>
						<label> Trending Category  </label>
					</div>
	
					<div className="form-group">
					<label>Category Image (Black Theme):</label>
					<div className="choose-file custom-img-resize">
					{this.state.isHidden && <div className="image_success"></div>}
					   <span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					{cat_image_preview && <img src={cat_image_preview} />}
					<div className="errorimage"></div>
					</div>
	
					<div className="form-group">
					<label>Category Image (White Theme):</label>
					<div className="choose-file custom-img-resize">
					{this.state.isHidden1 && <div className="image_success1"></div>}
					   <span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler1} /></span>
					</div>
					{cat_image_preview1 && <img src={cat_image_preview1} />}
					<div className="errorimage1"></div>
					</div>
	
					<div className="form-group">
					<label>Category Image (Yellow Theme):</label>
					<div className="choose-file custom-img-resize">
					{this.state.isHidden2 && <div className="image_success2"></div>}
					   <span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler2} /></span>
					</div>
					{cat_image_preview2 && <img src={cat_image_preview2} />}
					<div className="errorimage2"></div>
					</div>
				
				
				</div>
					  
				<div className="form-right">
				{/*<div className="form-group">
						<label>Short Name<span class="required" style={{ color: "red" }} > * </span> : </label>
						<input type="text" name="short_name" onChange={this.handleInputChange} className="form-control" value={this.state.short_name} />
						<div className="errorshort_name"></div>
					</div>*/}
	
					<div className="form-group">					
						<label>Short Description:</label>
							<textarea type="text" name="short_description" onChange={this.handleInputChange} className="form-control" value={this.state.short_description}  />
						<div className="errorshort_description"></div>
					</div>
	
					<div className="form-group">	
						<label>Priority<span class="required" style={{ color: "red" }} > * </span> : </label>
						<input type="text" name="cat_priority" onChange={this.handleInputChange} className="form-control" value={this.state.cat_priority} />
						<div className="errorcat_priority"></div>
					</div>
	
					<div className="form-group">
						<label>Show Type<span class="required" style={{ color: "red" }} > * </span> : </label>
						<select  onChange={this.handleClickSelecetForm} > 
						<option key='products' data-key='products'  value='products' >Products</option>
						<option  key='forms' data-key='forms'  value='forms' >Form Builder</option>
						</select>
						<div className="errorcategory_name"></div>
					 </div>
	
				
	
						 <div className="form-group">
						 <label>Category Background: </label>
							<input onChange={this.handleInputChange} type="radio" name="category_bg" checked={this.state.category_bg=='white'?'chekced':''} value="white"/>
						<span>White</span>
						 <input onChange={this.handleInputChange} type="radio"  name="category_bg" checked={this.state.category_bg=='black'?'chekced':''} value="black"/>
						<span>Black</span>
					</div> 
	
					  { /* ((this.state.products == 1))? 
					  <>
				   <div className="form-group">
						<label>Category Plan<span class="required" style={{ color: "red" }} > * </span> : </label>
						<div className="errorcat_plan"></div>
					</div>
					</>
				: ''  */}
	
				{ ((this.state.forms == 1))? 
					  <>
					  <div className="form-group">
						<label>Form Builder (Forms)<span class="required" style={{ color: "red" }} > * </span> : </label>
						<select  onChange={current.handleClickSelecetFormbuilder}  value={formbuildernamelist.form_name}>                    
						{this.state.formbuildernamelist.map((formbuildernamelist) => <option key={formbuildernamelist.value} data-key={formbuildernamelist.value} value={formbuildernamelist.value} >{formbuildernamelist.label}</option>)}
						</select>
						<div className="errorcategory_name"></div>
					 </div>
					</>
				: '' }
	
	
	
				
	
					
					
				   <div className="form-group">					
						<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
							   <Select 
							   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
								options={lang.common.status_option} 
								onChange={this.handleChange}
								/>
				   </div> 
	
				</div>	
				</div>		
	
				<div className="btn-group export">	
					<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
										<span className="load-data"></span> 
										}Submit
				   </button>
				</div>
				</form>
				</div>
					
			</div>	
		</div>
				 
		</div>
		);
	  }
}


export default (withRouter(Add));