import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";

import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { apiUrl} from'../Config/Config';
import axios from 'axios';
/* import { GET_DASHBOARDLIST  } from '../../actions'; */

class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      dashboardlist:'',
      totalRecordStore:0,
      totalRecordProducts:0,
      totalRecordCompanies:0,
      totalRecordOrders:0,
      totalRecordProductCategory: 5,
      totalstore:0,
    };
    
    var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var loggedas = localStorage.getItem("loggedas");
    var postobject = {
    activePage: 1,
    search_all: '',
    admin_id: admin_id,
    loggedas: loggedas
    };

    axios.post(apiUrl+"dashboard/list",qs.stringify(postobject)).then(res => {
      console.log(res.data,'testingdashborduse')
      if(res.data.status === 'success'){
        this.setState({ 
          totalRecord : res.data.records_count_product, 
          totalRecordUsers : res.data.records_count_users,
          totalRecordCompanies : res.data.records_count_companies,
          totalRecordcategory : res.data.records_count_category,
          totalRecordpayment : res.data.records_count_payment,
          });
      }
    }); 

    }

   
    componentDidMount() {
   }




  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="dashboard" />
      
      <div className="content"> 
        <div className="content-wrapper">
          <div className='content-wrapper-before'>
          </div>
              <div className="car-listing-wrap dashboard-wrap leaa-admin-head card">

                  <div className="title">
                    <h3>Dashboard</h3>
                  </div>

                  <div className="form-row first">

                        {localStorage.getItem('admin_id') === '1' ?  
                        <div className="form-left">
                        <div className="card pull-up border-top-info border-top-3 rounded-0">
                            <div className="card-header">
                                <h4 className="card-title card-badge">{this.state.totalRecordUsers}</h4>
                            </div>
                              <a  href="/user" className="">
                            <div className="card-content collapse show">
                                <div className="card-body p-1">
                                    <h4 className="font-large-1 text-bold-400">Users <i className="ft-users float-right"></i></h4>
                                </div>
                                <div className="card-footer p-1">
                                </div>
                            </div>
                       </a>
                    </div>
                          </div>
                        // <div className="form-left">
                        // <a href="user" className="dashboard-box" style={{backgroundColor:'#063f7b'}}>           
                        //    <h3 className="count">{this.state.totalRecordUsers}</h3> 
                        //    <p> Users</p>
                        // </a>
                        //   </div> : ''}
:""}
                        {localStorage.getItem('admin_id') === '1' ? 
                        //  <div className="form-right">
                        //      <a href="company" className="dashboard-box" style={{backgroundColor:'#2d3844'}}>               
                        //            <h3 className="count">{this.state.totalRecordCompanies}</h3> 
                        //            <p> Companies</p>
                        //         </a>
                        //   </div>: ''}
                        <div className="form-right">
                            <div className="card pull-up border-top-info-red border-top-3 rounded-0">
                            <div className="card-header">
                                <h4 className="card-title card-badge-red card-title-red ">{this.state.totalRecordCompanies}</h4>
                            </div>
                              <a href="/company" className="">
                            <div className="card-content collapse show">
                                <div className="card-body p-1">
                                    <h4 className="font-large-1 text-bold-400">Companies<i className="ft-briefcase float-right"></i></h4>
                                </div>
                                <div className="card-footer p-1">
                                </div>
                            </div>
                       </a>
                    </div>
                          </div>:""}
                    </div> 

                    <div className="form-row ">
                    <div className="form-left">
            					
                      <div className="card pull-up border-top-info-yellow border-top-3 rounded-0">
                          <div className="card-header">
                              <h4 className="card-title card-badge-yellow card-title-yellow">{this.state.totalRecordcategory}</h4>
                          </div>
                            <a href="/store" className="">
                              <div className="card-content collapse show">
                                  <div className="card-body p-1">
                                      <h4 className="font-large-1 text-bold-400">Categories<i className="ft-server float-right"></i></h4>
                                  </div>
                                  <div className="card-footer p-1">
                                  </div>
                              </div>
                        </a>
                      </div>   
                    </div>
            					{/* <div className="form-left">
                      <a href="category" className="dashboard-box" style={{backgroundColor:'#07c1de'}}>
                               <h3 className="count">{this.state.totalRecordcategory}</h3> 
                               <p> Categories</p>
                            </a>
            						
            					</div> */}
            					{/* <div className="form-right">
                      <a href="product" className="dashboard-box" style={{backgroundColor:'#0778ef'}}>
                           <h3 className="count">{this.state.totalRecord}</h3>   
                           <p> Products</p>
                        </a>
            					</div> */}
                       <div className="form-right">
                         <div className="card pull-up border-top-info-purple border-top-3 rounded-0">
                            <div className="card-header">
                            <h4 className="card-title card-badge-purple card-title-purple">{this.state.totalRecord}</h4>
                             </div>
                              <a href="/product" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Products <i className="ft-users float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                            </a>
                          </div>
            					  </div>  
            				</div>

                    {/* <div className="form-row ">
                      <div className="form-left">
                      <a href="payment-history" className="dashboard-box" style={{backgroundColor:'#2d3844'}}>
                               <h3 className="count">{this.state.totalRecordpayment}</h3> 
                               <p> Total Orders</p>
                            </a>
                        
                      </div>

                    </div> */}
                    {/* <div className="form-right">
                         <div className="card pull-up border-top-info-purple border-top-3 rounded-0">
                            <div className="card-header">
                            <h4 className="card-title card-badge-purple card-title-purple">{this.state.totalRecordpayment}</h4>
                             </div>
                              <a href="/productcategory" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Total Orders<i className="ft-users float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                            </a>
                          </div>
            					  </div> */}
                      
                </div>
                
          </div>  
      </div>
      
    </div>
    );
  }
}


export default List;