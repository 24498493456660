import React, { Component } from 'react';


import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl,productURL} from'../Config/Config';
import moment from 'moment';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap"; 
import { useParams, useNavigate } from 'react-router-dom'

import noimage from '../../common/images/no-image.png';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const paymenthistoryid = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
    this.state = {      
      paymenthistoryid: paymenthistoryid,
      paymenthistoryview:'',
    };

    var qs = require('qs');
    var postObject = {             
    id   :paymenthistoryid
    };
    
    axios.post(apiUrl+"product/paymentview",qs.stringify(postObject)).then(res => {
        if(res.data.status === 'success'){
          console.log( res.data.colorlistview)
            this.setState({ paymenthistoryview: res.data.paymenthistoryview[0],paymenthistorylist:res.data.paymenthistoryview,total_amt: res.data.total_amt});
  
        }
      }); 
  }

   
   componentDidMount() {
     document.title = PageTitle('Payment View');
   }

   componentDidUpdate(prevProps,prevState){

   }
   componentWillReceiveProps(Props){
      if(Props.paymenthistoryview!==this.state.paymenthistoryview){
        if(Props.paymenthistoryview[0].status == "success"){
          this.setState({paymenthistoryview: Props.paymenthistoryview[0].paymenthistoryview[0],paymenthistorylist: Props.paymenthistoryview[0].paymenthistoryview,total_amt: Props.paymenthistoryview[0].total_amt }) 
        }
      }
   }

    paymenthistorylist() {
    var productdetails = this.state.paymenthistorylist;
    console.log(productdetails)
    if (productdetails != "undefined" && productdetails != null) {
      if (productdetails.length > 0) {

        if (Object.keys(productdetails).length > 0) {
          const helperlistDetails = productdetails.map(
            (productdetails, Index) => {
              let sno = Index+1;
              let catImg = '';
              let preview = '';          

           /*    if (productdetails.product_image!== null && productdetails.product_image!== '') {
                catImg = productURL + "/" + productdetails.product_image;
              preview = <img className="" src={catImg} alt="" />;
            } */


              var product_image = '"'+productdetails.product_image+'"';

              if(product_image.match(/a:/g)){
              if (productdetails.product_image!== null && productdetails.product_image!== '') {
              var split_str = JSON.stringify((productdetails.product_image));
              var get_result = split_str.split('\"');
              var final_result = get_result[2];
              var  image_prod_final = final_result.replace(/[\/\\]/g,'');
              //console.log();
              catImg = productURL + "/" + image_prod_final;
              preview = <div className="listing-img">
              <a href="#" className=""><img className="img_class img-product-payment"  src={catImg} alt="" /> </a>
              </div>;
              }
              }else{
              if (productdetails.product_image!== null && productdetails.product_image!== '') {
              catImg = productURL + "/" + productdetails.product_image;
              preview =<div className="listing-img">
              <a href="#" className=""><img className="img_class img-product-payment"  src={catImg} alt="" /> </a>
              </div>;
              }
              }

              return (
                <tr key={productdetails.id}>
      					<td>{sno}</td>
      					<td>{productdetails.product_name}</td>
      					<td>{preview}</td>
      					<td>{productdetails.category_search_name}</td>
      					<td>${productdetails.price}</td>
      					<td>{productdetails.product_quantity}</td>
      					<td>${productdetails.original_amount}</td>
      			    
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
      }
    }
  }


  
  render() {
    const navigate = this.props.navigate;

    let payment_plan = 'N/A';

if (this.state.paymenthistoryview && this.state.paymenthistoryview.payment_type) {
  payment_plan = this.state.paymenthistoryview.payment_type === "onetime" 
    ? 'N/A' 
    : this.state.paymenthistoryview.payment_type;
}

    const {paymenthistorylist,paymenthistoryview} = this.state;
    let payment_type = '';

    if (this.state.paymenthistoryview && this.state.paymenthistoryview.payment_type) {
      payment_type = this.state.paymenthistoryview.payment_type;
    }

    var paid_status  = this.state.paymenthistoryview.paid_status;
if((this.state.paymenthistoryview.user_note!='')&&(this.state.paymenthistoryview.user_note!=null)){
  var user_note    = this.state.paymenthistoryview.user_note;
}else{
  var user_note    = 'N/A';
}


 var lasturrl = window.location.href.substr(window.location.href.lastIndexOf('/') +1);
   

    return (
      <div className="wrapper"> 
 
    <Header />

    {(lasturrl ==='completed')?
    <>
     <MenuSidebar currentpage="payment-completed-history" />
     </>:<>
     <MenuSidebar currentpage="payment-history" />
    </>
    }
    
      
    <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
               <div className="back-btn-lux">
                  <a onClick={() => navigate(-1)}>Back</a>    
                </div> 
                <div className="title">
                  <h3>Order History</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>User Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{paymenthistoryview.username}</p>
                        </div>
                      </div>
                     {(paymenthistoryview.email !=null && paymenthistoryview.email!='')&& <div className="view-details">
                        <div className="view-details-one">
                            <p><b>User Email<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{paymenthistoryview.email}</p>
                        </div>
                      </div>}
                      {(paymenthistoryview.mobileno !=null && paymenthistoryview.mobileno!='')&&<div className="view-details">
                        <div className="view-details-one">
                            <p><b>User Mobile<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{paymenthistoryview.mobileno}</p>
                        </div>
                      </div>}
                      
                      {/* <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Specialist<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{productorderlistdata.specialist_name}</p>
                        </div>
                      </div> */}

                      {/* <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Category<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{productorderlistdata.catname}</p>
                        </div>
                      </div> */}
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Transaction ID<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p> {paymenthistoryview.transaction_id}</p>
                        </div>
                      </div>
                    

                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Ordered On<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>  {moment(paymenthistoryview.created_on).format('MMM DD Y hh:mm A')}</p>
                        </div>
                      </div>


                      


                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Order Detail </b></p>
                        </div>
                       
                      </div>
                      <div className="car-listing-row table-avrech">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th>S.No</th>
			            <th>product Name</th>
                  <th>Image</th>
			            <th>Category</th>
			            <th>Price</th>
			            <th>Quantity</th>
			            <th>Total Price</th>
                   </tr>
                  </thead>
                  <tbody>
                  {this.paymenthistorylist()}
                  </tbody>
                </Table>
			        	</div>
                <br></br>
                <div className="view-details-right ">
                        <div className="view-details-right-one">
                            <p><b>Paid Amount<span> : ${this.state.total_amt}</span> </b></p>
                        </div>
                      
                      </div>
                      
                      </div>
                    

                      </div>
                      </div>
               
                    
                     
                    
           
              </div>            
            
          </div>
         </div> 
        </div>         
    </div>
    );
  }
}


export default withRouter(View);