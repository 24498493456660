import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import $ from 'jquery';
import { lang } from '../Helpers/lang';

import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { baseUrl } from '../Config/Config';

class List extends Component {
  
  constructor(props)
  {
     super(props); 
      this.state = {
          packagelist:'',
          activePage: 1,
          totalRecord: '',
          search_all:'',
          selectedStatus:'',
          selectid:'',
      };
    

      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);

    }

   
   componentDidMount() {
   this.listloadedData();
   }
   listloadedData(postobject=''){ 
    var qs = require('qs');
    if(postobject!=''){
      var postobject = postobject
    }else{
     
      var postobject = {
      activePage: 1,
      search_all: '',
      admin_id: localStorage.getItem('admin_id')  
      };
    }
 
   axios.post(apiUrl+"package/list",qs.stringify(postobject)).then(res => {
     
     if(res.data.status === 'success'){
         this.setState({  packagelist: res.data, totalRecord: res.data.records_count}, function() {
           this.packagelist();
         });
     }
   }); 
}

  handlePageChange(pageNumber) {
   
    this.setState({activePage: pageNumber});
    var qs = require('qs');
    var postobject = {
      activePage: pageNumber,
      search_all: this.state.search_all,
      admin_id: localStorage.getItem('admin_id')  
    };
    this.listloadedData(postobject)
  }

  handleInputChange(event) {
  const {name, value} = event.target; 
  var postObject = {             
    activePage   : 1,
    search_all : value,
    admin_id: localStorage.getItem('admin_id')           
  };     
  this.setState({
    [name]: value
  },function(){
    this.listloadedData(postObject)
  });
  
}
 handleFormSubmit = () => {
      
        this.setState({Loading:true});
        var qs = require('qs');
        const formPayload = this.state;
         
        var postObject = {             
          activePage   : 1,
          search_all : formPayload.search_all,
          admin_id: localStorage.getItem('admin_id')           
        };
        this.listloadedData(postObject)
      
  } 

   componentWillReceiveProps(Props){
   }

// deleteuser(id){
//         var qs = require('qs');
//         var postObject = {
//           admin_id : localStorage.getItem("admin_id"),
//           rehab_id  :id
//         };
//         axios.post(apiUrl+"rehab/list",qs.stringify(postObject)).then(res => {      
//           if(res.data.status === 'success'){
//               this.setState({  rehablist: res.data, totalRecord: res.data.records_count}, function() {
//                 this.rehablist();
//               });
//           }
//         }); 
// }



activaterehab(){
  
	let stat = ''
		if(this.state.selectedStatus ==='inactive'){
			stat = "I";
		}else{
			stat = "A";
		}  

        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          id  :this.state.selectid,
		     status: stat
        };
        axios.post(apiUrl+"package/change_status",qs.stringify(postObject)).then(res => {      
          if(res.data.status === 'success'){
              this.listloadedData()
              $('.success_message').html('<div class="status_sucess"><h3> '+ res.data.message +' </h3></div>');
              setTimeout(
                function() {
               
                  $('.success_message').html('');
                   
                }
                .bind(this),
                3000
                );
                
          }
        }); 
}

confirmPopup = (id, status) => {
  var status = status;
  console.log(status,'statusnewssssssssssss')
  if(status == 'Inactive'){
    status = "active";
  }else if(status == 'Active'){
    status = "inactive";
  } else{
    status="Delete";
  }
  this.setState({selectid: id, selectedStatus: status})	
  $('.confirm-action').addClass('show');		
}

Confirmstatus = (confirmstatus) => {
  if(confirmstatus == 'yes'){
    this.activaterehab();
    $('.confirm-action').removeClass('show');
  }else{
    $('.confirm-action').removeClass('show');	
  }
    
}
exportdata(){
	let stat = '';		
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id")
        };
        axios.post(apiUrl+"Rehab/export",qs.stringify(postObject)).then(res => {      
          if(res.data.status === 'success'){
            var url = res.data.url;
            var cleanurl = url.replace(/([^:]\/)\/+/g, "$1");
            window.open(cleanurl, '_blank', 'noreferrer');
            // window.location.reload();
          }
        }); 
      
}
activateuser(id, status){
  let stat = ''
    if(status == 'Inactive'){
      stat = "A";
    }else{
      stat = "I";
    }
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          id  :id,
          status: stat
        };
      this.props.getActivatePackage(qs.stringify(postObject));
}
packagelist() {

  var packagelist = this.state.packagelist;
  console.log(packagelist,'this is package list fro ')
  if (packagelist != "undefined" && packagelist != null) {

    if (packagelist.status == "success") {

      if (Object.keys(packagelist).length > 0) {
        const vehiclelistDetails = packagelist.packagelist.map(
          (packagelist, Index) => {
            let sno = Index+1;
            console.log(packagelist.status)
            if(packagelist.status == 'A'){
              var status = 'Active';
            }else{
                status = 'Inactive';
            }

          var string = '';
          if (packagelist.package_content!=='' && packagelist.package_content.length > 25) {
            string = packagelist.package_content.substring(0, 150) + "...";
            var stripedHtml_one = string.replace(/<[^>]+>/g, '');
            var stripedHtml =stripedHtml_one.replace(/&nbsp;/g,'');
            console.log('stripedHtml',stripedHtml)
          }

       

           var istyle = { width: 100};
            return (
              <tr key={packagelist.id}>
                  <td>{sno}</td>
                  <td>{packagelist.type}</td>
                  <td>{stripedHtml}</td>
                  <td>{packagelist.price}</td>
                  <td>{status}</td>
                   {localStorage.getItem('loggedas') === 'admin' &&
                    <> 
                  <td className="actiongroup">
                    <Link to={{ pathname: '/packages-edit/'+packagelist.id}} className="" title="Edit">  <i className="ft-edit-3" aria-hidden="true"></i></Link>
                     {(status == 'Active'?<Link onClick={this.confirmPopup.bind(this, packagelist.id, status)} className="" title="Status"><i className="fa ft-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.confirmPopup.bind(this, packagelist.id, status)} className="" title="Status"><i className="fa ft-lock" aria-hidden="true"></i></Link>)}     
                  </td>
                  </>}
              </tr>
            );
          }
        );
        return vehiclelistDetails;
      }
    } else {

      return (
        <tr>
          <td colspan="6" className="v-align-nr">
            No Result
          </td>
        </tr>
      );

    }
  }

}
  
  render() {
    const navigate = this.props.navigate;
    return (
      <div className="wrapper"> 
    <div className="confirm-action">
			<p>Are you sure! you want to {this.state.selectedStatus} the package?</p>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		</div>
    <Header />
    <MenuSidebar currentpage="packages" />  

  
  <div className="content"> 
    <div className="content-wrapper">
    <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
    <div className="success_message"></div>
        <div className="listing-header"> 
          <div className="title">
          {this.state.totalRecord > 1 ?<h3>{this.state.totalRecord} Packages</h3>:<h3>{this.state.totalRecord} Package</h3>}
          </div>
            <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
          </form>
                </div>
          <div className="add_button">  
      		 {/* <a className="" href='/rehabs-add'>Add &nbsp;|&nbsp;</a> */}
			
           {/* <Link onClick={this.exportdata.bind(this)} className="" title="Export"> Export <i className="export-icon icon" aria-hidden="true"></i></Link> */}
          </div>
        </div>

        <div className="car-listing-row">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
			          <th>Package Name</th>
              
                {/* <th>Email</th> */}
                <th>Package Description	</th>
                {/* <th>Started Year</th> */}
                <th>Package Price	</th>
               
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{this.packagelist()}</tbody>
          </Table>
          <div className="text-center">
            <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>
        </div>
                      
      </div>  
    </div>  
  </div>
      
    </div>
    );
  }
}

export default List;