import React from "react";
import Select from "react-select";

const VariantRow = ({ row, index, catenamelist,cateweightlist,catecolorlist,catesizelist,onImageChange, catevarientlist, onInputChange, onSelectChange, onDelete }) => {
  return (
    <div className="form-row add-variants ">
      <div className="form-left">
        <div className="form-group">
          <label>
            Size
            <span className="required" style={{ color: "red" }}> *</span>:
          </label>
          <Select
            name="selectedsizeVariantcateOption"
            value={row.selectedsizeVariantcateOption || catesizelist[0]}
            options={catesizelist} 
            placeholder="Select a size"
            isSearchable={false}
            onChange={(selectedOption) => onSelectChange(index, "selectedsizeVariantcateOption", selectedOption)}
          />
        </div>
        <div className="form-group">
          <label>
           Weight type
            <span className="required" style={{ color: "red" }}> *</span>:
          </label>
          <Select
            name="selectedweightVariantOption"
            value={row.selectedweightVariantOption }
            options={cateweightlist}
            isSearchable={false}
            onChange={(selectedOption) => onSelectChange(index, "selectedweightVariantOption", selectedOption)}
            placeholder="Select a weight"
          />
        </div>
        <div className="form-group">
          <label>
            Addon price $
            <span className="required" style={{ color: "red" }}> *</span>:
          </label>
          <input
            type="text"
            name="addonprice"
            className="form-control"
            value={row.addonprice}
            onChange={(e) => onInputChange(index, e)}
          />
          
        </div>
       
      </div>
      <div className="form-right">
       

        <div className="form-group">
          <label>
           Color
            <span className="required" style={{ color: "red" }}> *</span>:
          </label>
          <Select
            name="selectedcolorVariantOption"
            value={row.selectedcolorVariantOption || catecolorlist[0] }
            options={catecolorlist}
            isSearchable={false}
            onChange={(selectedOption) => onSelectChange(index, "selectedcolorVariantOption", selectedOption)}
            placeholder="Select a variant category"
          />
        </div>
         {row.selectedweightVariantOption &&  (
              <div className="form-group">
                <label>Weight  :</label>
                <input
                  type="number"
                  name="weightValue"
                  value={row.weightValue || ''}
                  onChange={(e) => onInputChange(index, e)}
                
                  className="form-control"
                 
                />
              </div>
            )}
        <div className="form-group">	
								<label>Quantity<span class="required" style={{ color: "red" }} > * </span> : </label>
								<input type="text" name="variant_quantity" 
                    onChange={(e) => onInputChange(index, e)}
                    className="form-control" 
                    value={row.variant_quantity}
                 />
				</div>
          <div className="form-group">
            <label>Image <span className="required" style={{ color: "red" }}> *</span>:</label>
              <input
                type="file"
                name="variant_image"
                className="form-control"
                accept="image/*"
                onChange={(e) => onImageChange(index, e)}
              />
             
              {row.variant_image && row.variant_image.preview && row.variant_image.preview !== "https://admin-f.uxt.design/formbooking/media//product_variants/null" && (
                  <img
                    src={row.variant_image.preview}
                    alt="Preview"
                    style={{ width: "100px", height: "100px", marginTop: "10px", objectFit: "cover" }}
                  />
                )}
          </div>

      </div>
      <a class="delete-icon-btn" title="delete"  onClick={() => onDelete(index)}>  <i class="ft-trash-2" aria-hidden="true"></i></a>
      
    </div>
  );
};

export default VariantRow;
