import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Select from 'react-select';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { apiUrl, carImageUrl, currency, dummycarUrl,productURL} from'../Config/Config';
import axios from 'axios';

import 'react-confirm-alert/src/react-confirm-alert.css'; 

const qs = require('qs');
const lasturrl = window.location.href.substr(window.location.href.lastIndexOf('/') +1);
class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      productlist:'',
      domainlist:[],
      cat_name:'',
      categorynamelist:[],  
      activePage:1,
      selectedStatus:'',
    };
    
    var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var loggedas = localStorage.getItem("loggedas");
    var postobject = {
    activePage: 1,
    search_all: '',
    admin_id: admin_id,
    loggedas: loggedas
    };
    this.handleClickSelecetCategory = this.handleClickSelecetCategory.bind(this);
    axios.post(apiUrl+"product/list",qs.stringify(postobject)).then(res => {
   
      if(res.data.status === 'success'){

        // if (Object.keys(Props.productlist).length > 0) {
        //   if (Props.productlist[0]["status"] === "success") {
        //     this.setState({ totalRecord : Props.productlist[0].records_count,totalRecord_out : Props.productlist[0].records_count_out, productlist: Props.productlist[0]["productlist"] });
        //     this.productlist();
        //   }
        // }
          this.setState({  productlist: res.data, totalRecord: res.data.records_count,totalRecord_out:  res.data.records_count_out}, function() {
            this.productlist();
          });
      }else{
       this.setState({  productlist: '', totalRecord: 0}, function() {
         this.productlist();
       });
      }
    }); 
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    

    componentDidMount() {

      var admin_id = localStorage.getItem('admin_id');
    axios.get(apiUrl+'product/getcategorynamelist?admin_id='+admin_id)
      
      .then(res => {
        if(res.data.status == 'success'){
        
          this.setState({
            categorynamelist: [{value: 'all', label: 'Select Category'}].concat(res.data.categorynamelist)
          })
        
        }else{
        //console.log("test")
        }
      });

   }

   handlePageChange(pageNumber) {
    var lasturrl = window.location.href.substr(window.location.href.lastIndexOf('/') +1);
    // console.log(`active page is ${pageNumber}`);
     this.setState({activePage: pageNumber});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");
     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       admin_id: admin_id,
       loggedas:loggedas
     };
    // this.props.getProductList(qs.stringify(postobject))

     axios.post(apiUrl+"product/list",qs.stringify(postobject)).then(res => {
   
      if(res.data.status === 'success'){
  
        // if (Object.keys(Props.productlist).length > 0) {
        //   if (Props.productlist[0]["status"] === "success") {
        //     this.setState({ totalRecord : Props.productlist[0].records_count,totalRecord_out : Props.productlist[0].records_count_out, productlist: Props.productlist[0]["productlist"] });
        //     this.productlist();
        //   }
        // }
          this.setState({  productlist: res.data, totalRecord: res.data.records_count,totalRecord_out:  res.data.records_count_out}, function() {
            if(lasturrl ==='product-outstock'){
              this.productlist_outstock();
            }else{
              this.productlist();
            }
           
          });
      }else{
       this.setState({  productlist: '', totalRecord: 0, totalRecord_out: 0}, function() {
        if(lasturrl ==='product-outstock'){
          this.productlist_outstock();
        }else{
          this.productlist();
        }
       });
      }
    }); 
   }

   handleInputChange(event) {
   const {name, value} = event.target;      
   this.setState({
     [name]: value
   });
   
 }

 handleFormSubmit = () => {
        
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
   var admin_id = localStorage.getItem("admin_id");
   var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    admin_id: admin_id,
    loggedas: loggedas         
  };
  //console.log(postObject);
 // this.props.getProductList(qs.stringify(postObject));
  var lasturrl = window.location.href.substr(window.location.href.lastIndexOf('/') +1);
  axios.post(apiUrl+"product/list",qs.stringify(postObject)).then(res => {
   
    if(res.data.status === 'success'){
        this.setState({  productlist: res.data, totalRecord: res.data.records_count,totalRecord_out:  res.data.records_count_out}, function() {
          if(lasturrl ==='product-outstock'){
            this.productlist_outstock();
          }else{
            this.productlist();
          }
         
        });
    }else{
     this.setState({  productlist: '', totalRecord: 0, totalRecord_out: 0}, function() {
      if(lasturrl ==='product-outstock'){
        this.productlist_outstock();
      }else{
        this.productlist();
      }
     });
    }
  }); 

} 



   componentWillReceiveProps(Props){

/*      if (Props.deleteproduct!== this.props.deleteproduct) {
      if (Props.deleteproduct[0]["status"] === "success") {
         
          $('.success_message').html('<div class="status_sucess"><h3>'+ Props.deleteproduct[0]["message"]+'</h3></div>');
           var qs = require('qs');
              var admin_id = localStorage.getItem("admin_id");
              var postobject = {
              activePage: 1,
              search_all: '',
              admin_id: admin_id
              };

              this.props.getProductList(qs.stringify(postobject));
           setTimeout(
          function() {
          $('.success_message').html('');
          //window.location.reload(); 
          }
          .bind(this),
          3000
          );


          this.setState({productlist: Props.deleteproduct[0]["productlist"]}, function() {
            this.productlist();
          });
       
          }
          
      } */

       if(Props.productactive !== this.props.productactive){
      if (Props.productactive[0]["status"] === "success") {

        $('.success_message').html('<div class="status_sucess"><h3>'+ Props.productactive[0]["message"]+'</h3></div>');
              var qs = require('qs');
              var admin_id = localStorage.getItem("admin_id");
              var postobject = {
              activePage: 1,
              search_all: '',
              admin_id: admin_id
              };

              this.props.getProductList(qs.stringify(postobject));


           
           setTimeout(
          function() {
          $('.success_message').html('');
          //window.location.reload(); 
          }
          .bind(this),
          3000
          );

      }
    }
     
   }

   deleteproduct(id){
    var qs = require('qs');
    var postObject = {
      admin_id : localStorage.getItem("admin_id"),
      id  :id
    };
    var lasturrl = window.location.href.substr(window.location.href.lastIndexOf('/') +1);
    
  axios.post(apiUrl+"product/delete",qs.stringify(postObject)).then(res => {
   
    if(res.data.status === 'success'){
      $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
      
      var admin_id = localStorage.getItem("admin_id");
      var loggedas = localStorage.getItem("loggedas");
      var postobject = {
        activePage: this.state.activePage,
        search_all: '',
        admin_id: admin_id,
        loggedas: loggedas
        };
      //this.props.getProductList(qs.stringify(postobject));
      axios.post(apiUrl+"product/list",qs.stringify(postobject)).then(res => {
 
        if(res.data.status === 'success'){
  
            this.setState({  productlist: res.data, totalRecord: res.data.records_count,totalRecord_out:  res.data.records_count_out}, function() {
              if(lasturrl ==='product-outstock'){
                this.productlist_outstock();
              }else{
                this.productlist();
              }
             
            });
        }else{
         this.setState({  productlist: '', totalRecord: 0, totalRecord_out: 0}, function() {
          if(lasturrl ==='product-outstock'){
            this.productlist_outstock();
          }else{
            this.productlist();
          }
         });
        }
      });
    }
    setTimeout(
      function() {
      $('.success_message').html('');
      //window.location.reload(); 
      }
      .bind(this),
      2000
      );
  }); 
}

activateproduct(id, status){
  var lasturrl = window.location.href.substr(window.location.href.lastIndexOf('/') +1);
  let stat = '';
  console.log(this.state.selectedStatus,'this woring status')
    if(this.state.selectedStatus == 'Inactive'){
      stat = "I";
    }else{
      stat = "A";
    }
    console.log(this.state.selectedStatus,'this is working for active')
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          id  :this.state.selectid,
          status:stat
        };
      //this.props.getActivateProduct(qs.stringify(postObject));
        console.log(this.state.activePage,'activePage')
      axios.post(apiUrl+"product/change_status",qs.stringify(postObject)).then(res => {
        if(res.data.status === 'success'){
          $('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
          var qs = require('qs');
          var admin_id = localStorage.getItem("admin_id");
          var loggedas = localStorage.getItem("loggedas");
          var postobject = {
          activePage: this.state.activePage,
          search_all: '',
          admin_id: admin_id,
          loggedas: loggedas
          };
          axios.post(apiUrl+"product/list",qs.stringify(postobject)).then(res => {
         
            if(res.data.status === 'success'){
                this.setState({  productlist: res.data, totalRecord: res.data.records_count,totalRecord_out:  res.data.records_count_out}, function() {
                  if(lasturrl ==='product-outstock'){
                    this.productlist_outstock();
                  }else{
                    this.productlist();
                  }
                });
            }else{
             this.setState({  productlist: '', totalRecord: 0}, function() {
               if(lasturrl ==='product-outstock'){
                this.productlist_outstock();
              }else{
                this.productlist();
              }
             });
            }
          }); 
        }else{
         this.setState({  productlist: '', totalRecord: 0, totalRecord_out: 0}, function() {
          if(lasturrl ==='product-outstock'){
            this.productlist_outstock();
          }else{
            this.productlist();
          }
         });
        }

        setTimeout(
          function() {
          $('.success_message').html('');
          //window.location.reload(); 
          }
          .bind(this),
          3000
          );
      });
}

copyproduct(id){
  var admin_id = localStorage.getItem('admin_id');
  axios.get(apiUrl+'product/setproductcopy?admin_id='+admin_id+'&prodid='+id)
    
    .then(res => {
      if(res.data.status == 'success'){
        $('.success_message').html('<div class="status_sucess"><h3>Product copied successfully</h3></div>');

        setTimeout(
          function() {
          $('.success_message').html('');
          //window.location.reload(); 
          }
          .bind(this),
          2500
          );

        var admin_id = localStorage.getItem("admin_id");
        var loggedas = localStorage.getItem("loggedas");
        var postobject = {
          activePage: this.state.activePage,
          search_all: '',
          admin_id: admin_id,
          loggedas: loggedas
          };
        //this.props.getProductList(qs.stringify(postobject));
        axios.post(apiUrl+"product/list",qs.stringify(postobject)).then(res => {
   
          if(res.data.status === 'success'){
    
              this.setState({  productlist: res.data, totalRecord: res.data.records_count,totalRecord_out:  res.data.records_count_out}, function() {
                if(lasturrl ==='product-outstock'){
                  this.productlist_outstock();
                }else{
                  this.productlist();
                }
               
              });
          }else{
           this.setState({  productlist: '', totalRecord: 0, totalRecord_out: 0}, function() {
            if(lasturrl ==='product-outstock'){
              this.productlist_outstock();
            }else{
              this.productlist();
            }
           });
          }
        });
      }else{
      //console.log("test")
      }
    });
}
confirmPopup = (id, status) => {
  var status = status;
   
    if(status == 'Inactive'){
      status = "Active";
    }else if(status == 'Active'){
      status = "Inactive";
    }else if(status == 'Delete'){
      status="Delete";
    } else if(status =='Copy'){
        status="Copy";
    }
    console.log(status,'this is change time')
  this.setState({selectid: id, selectedStatus: status})	
  $('.confirm-action').addClass('show');		
}
productlist() {
  let catImg = '';
  let preview = '';

    var productlist = this.state.productlist.productlist;
    if (productlist != "undefined" && productlist != null && productlist!='') {
   
        if (Object.keys(productlist).length > 0) {
          const helperlistDetails = productlist.map(
            (productlist, Index) => {
              let sno = Index+1;
              
               if(productlist.status == 'A'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }

             var product_image = '"'+productlist.product_image+'"';
              
              if(product_image.match(/a:/g)){
                     if (productlist.product_image!== null && productlist.product_image!== '') {
                      var split_str = JSON.stringify((productlist.product_image));
                      var get_result = split_str.split('\"');
                      var final_result = get_result[2];
                     var  image_prod_final = final_result.replace(/[\/\\]/g,'');
                      //console.log();
                    catImg = productURL + "/" + image_prod_final;
                  preview = <img className="img_class"  src={catImg} alt="" />;
                }
              }else{
                if (productlist.product_image!== null && productlist.product_image!== '') {
                catImg = productURL + "/" + productlist.product_image;
                preview = <img className="img_class"  src={catImg} alt="" />;
                }
              }
              
              var istyle = { width: 100};
              return (
                <tr key={productlist.product_id}>
      					<td>{sno}</td>
      					<td>{productlist.product_name}</td>
                <td>{preview}</td>
               {/* <td>{productlist.short_name}</td>*/}
                <td>{productlist.cat_name}</td>
                
                <td>${productlist.price}</td>
               {/* <td>{productlist.product_color}</td>*/}
                <td>{productlist.product_quantity}</td>
                <td>{status}</td>       
                {/*<td>{productlist.product_priority}</td>  */}      
                <td className="actiongroup" >
                    
                    
                <Link to={{ pathname: '/product-view/'+productlist.product_id}} className="" title="View">  <i className="fa ft-eye" aria-hidden="true"></i></Link>
                <Link to={{ pathname: '/product-edit/'+productlist.product_id}} className="" title="Edit">  <i className="fa ft-edit" aria-hidden="true"></i></Link>  
                {(status == 'Active'?<Link onClick={this.confirmPopup.bind(this, productlist.product_id, status)} className="" title="Status"><i className="fa ft-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.confirmPopup.bind(this, productlist.product_id, status)} className="" title="Status"><i className="fa ft-lock" aria-hidden="true"></i></Link>)}  

                     <Link onClick={this.confirmPopup.bind(this,productlist.product_id,'Copy')}  className="" title="Copy Product">  <i className="fa fa-copy" aria-hidden="true"></i></Link>

                     <Link onClick={this.confirmPopup.bind(this,productlist.product_id,'Delete')} className="" title="Delete"><i className="ft-trash-2" aria-hidden="true"></i></Link>
                    
                    
                    
                  </td>
                </tr>
              );
            }
          );
          return helperlistDetails;
        } else {
        return (
          <tr>
            <td colspan="11" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }
  }



productlist_outstock() {
  let catImg = '';
  let preview = '';
    var productlist = this.state.productlist;
    if (productlist != "undefined" && productlist != null && productlist.productlist_outof_stock != '') {
      if (productlist.status == "success") {
        if (Object.keys(productlist).length > 0) {
          const helperlistDetails = productlist.productlist_outof_stock.map(
            (productlist, Index) => {
              let sno = Index+1;
              

               if(productlist.status == 'A'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }
              if (productlist.cat_image!== null && productlist.product_image!== '') {
              catImg = productURL + "/" + productlist.product_image;
            preview = <img className="img_class"  src={catImg} alt="" />;
          }
              var istyle = { width: 100};
              return (
                <tr key={productlist.product_id}>
                <td>{sno}</td>
                <td>{productlist.product_name}</td>
                <td>{preview}</td>
               {/* <td>{productlist.short_name}</td>*/}
                <td>{productlist.cat_name}</td>
                
                <td>${productlist.price}</td>
               {/* <td>{productlist.product_color}</td>*/}
                <td>{productlist.product_quantity}</td>
                <td>{status}</td>       
                {/*<td>{productlist.product_priority}</td>  */}      
                <td className="actiongroup" >
                    
                    
                <Link to={{ pathname: '/product-view-outstock/'+productlist.product_id}} className="" title="View">  <i className="fa ft-eye" aria-hidden="true"></i></Link>
                    <Link to={{ pathname: '/product-edit-outstock/'+productlist.product_id}} className="" title="Edit">  <i className="fa ft-edit" aria-hidden="true"></i></Link>
                       {(status == 'Active'?<Link onClick={this.confirmPopup.bind(this, productlist.product_id, status)} className="" title="Status"><i className="fa ft-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.confirmPopup.bind(this, productlist.product_id, status)} className="" title="Status"><i className="fa ft-lock" aria-hidden="true"></i></Link>)}      
                    {/* <Link onClick={this.deleteproduct.bind(this,productlist.product_id)} className="" title="Duplicate"><i className="fa fa-trash" aria-hidden="true"></i></Link> */}
                    
                    
                    
                  </td>
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="11" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }else {
      return (
        <tr>
          <td colspan="11" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }

  handleClickSelecetCategory = (selectedOption) => {
    if (!selectedOption) {
        console.error("No option selected.");
        return;
    }
    

      var lasturrl = window.location.href.substr(window.location.href.lastIndexOf('/') +1);
  
      this.setState({Loading:true});
    var qs = require('qs');
    const formPayload = this.state;
     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");
    var postObject = {             
      activePage   : 1,
      search_all : selectedOption.value,
      admin_id: admin_id,
      loggedas: loggedas         
    };
    axios.post(apiUrl+"product/list",qs.stringify(postObject)).then(res => {
     
      if(res.data.status === 'success'){
  
        // if (Object.keys(Props.productlist).length > 0) {
        //   if (Props.productlist[0]["status"] === "success") {
        //     this.setState({ totalRecord : Props.productlist[0].records_count,totalRecord_out : Props.productlist[0].records_count_out, productlist: Props.productlist[0]["productlist"] });
        //     this.productlist();
        //   }
        // }
          this.setState({  productlist: res.data, totalRecord: res.data.records_count,totalRecord_out:  res.data.records_count_out}, function() {
            if(lasturrl ==='product-outstock'){
              this.productlist_outstock();
            }else{
              this.productlist();
            }
           
          });
      }else{
       this.setState({  productlist: '', totalRecord: 0, totalRecord_out: 0}, function() {
        if(lasturrl ==='product-outstock'){
          this.productlist_outstock();
        }else{
          this.productlist();
        }
       });
      }
    }); 
}
  //  handleClickSelecetCategory = (event) => {
  //   const selectedIndex = event.target.options.selectedIndex;
  //   var keys = event.target.options[selectedIndex].getAttribute('data-key');

  //   var lasturrl = window.location.href.substr(window.location.href.lastIndexOf('/') +1);

  //   this.setState({Loading:true});
  // var qs = require('qs');
  // const formPayload = this.state;
  //  var admin_id = localStorage.getItem("admin_id");
  //  var loggedas = localStorage.getItem("loggedas");
  // var postObject = {             
  //   activePage   : 1,
  //   search_all : keys,
  //   admin_id: admin_id,
  //   loggedas: loggedas         
  // };
  // axios.post(apiUrl+"product/list",qs.stringify(postObject)).then(res => {
   
  //   if(res.data.status === 'success'){

  //     // if (Object.keys(Props.productlist).length > 0) {
  //     //   if (Props.productlist[0]["status"] === "success") {
  //     //     this.setState({ totalRecord : Props.productlist[0].records_count,totalRecord_out : Props.productlist[0].records_count_out, productlist: Props.productlist[0]["productlist"] });
  //     //     this.productlist();
  //     //   }
  //     // }
  //       this.setState({  productlist: res.data, totalRecord: res.data.records_count,totalRecord_out:  res.data.records_count_out}, function() {
  //         if(lasturrl ==='product-outstock'){
  //           this.productlist_outstock();
  //         }else{
  //           this.productlist();
  //         }
         
  //       });
  //   }else{
  //    this.setState({  productlist: '', totalRecord: 0, totalRecord_out: 0}, function() {
  //     if(lasturrl ==='product-outstock'){
  //       this.productlist_outstock();
  //     }else{
  //       this.productlist();
  //     }
  //    });
  //   }
  // }); 

    
  // }
  
  Confirmstatus =(confirmstatus)=>{
    console.log(this.state.selectedStatus,'confirmstatus')
    if(confirmstatus == 'yes'){
        if(this.state.selectedStatus=="Delete"){
          this.deleteproduct(this.state.selectid);
        }else if(this.state.selectedStatus=="Copy"){ 
          this.copyproduct(this.state.selectid);
        } else{
          this.activateproduct();
        }
        $('.confirm-action').removeClass('show');
    }else{
      $('.confirm-action').removeClass('show');	
    }
  }

  render() {
   
    const {domainlist,universitynamelist,categorynamelist} = this.state;
    const categoryOptions = categorynamelist.map(category => ({
      value: category.label,
      label: category.label
    }));
	  var current = this;
    var lasturrl = window.location.href.substr(window.location.href.lastIndexOf('/') +1);
    console.log(lasturrl,'lasturrl')
    var admin_id = localStorage.getItem('admin_id');
   let catImg = '';
  let preview = '';
    return (
      <div className="wrapper"> 
 
    <Header />
    {(lasturrl ==='product-outstock')?
    <>
     <MenuSidebar currentpage="product-outstock" />
     </>:<>
     <MenuSidebar currentpage="product" /> 
    </>
  }
    
      <div className="content"> 
        <div className="content-wrapper">
        <div className="content-wrapper-before"></div>
        <div className="car-listing-wrap leaa-admin-head card">
            <div className="success_message"></div>
              <div className="listing-header">
                <div className="title">
                {(lasturrl ==='product-outstock')?
                  <>
                   {this.state.totalRecord_out > 1 ?<h3>{this.state.totalRecord_out} Out Of Stocks</h3>:<h3>{this.state.totalRecord_out} Out Of Stock</h3>}
                   </>:<>
                   {this.state.totalRecord > 1 ?<h3>{this.state.totalRecord} Products</h3>:<h3>{this.state.totalRecord} Product</h3>}
                  </>
                }
                    
                </div>


                <div className="form-group">
                      <Select
                          options={categoryOptions}  
                          value={this.state.selectedCategory || { value: '', label: 'Select Category' }} // Adjust `selectedCategory` to match your state variable
                          onChange={(selectedOption) => current.handleClickSelecetCategory(selectedOption)}
                          placeholder="Select Category"
                        />
                    {/* <select  onChange={current.handleClickSelecetCategory}  >                    
                    {categorynamelist.map((categorynamelist) => <option key={categorynamelist.label} data-key={categorynamelist.label} value={categorynamelist.label} >{categorynamelist.label}</option>)}
                    </select> */}
              <div className="errorassign_paymenttype"></div>
              </div>

               

                <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>

                {(lasturrl ==='product-outstock')?
                  '': 
                  <div className="add_button">  
                    <a className="add" href='/product-add'>Add</a>
                </div>
                }
               
                </div>      
                         
              <div style={{color:"green", fontSize: 30}} className="statusmessage"></div>
            
              <div className="car-listing-row table-avrech">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th>S.No</th>
			            <th>Product Name</th>
                   <th>Image</th>
                  {/*<th>Short Name</th>*/}
                  <th>Category</th>
                 
                  <th>Price</th>
                  {/*<th>Color</th>*/}
                  <th>Quantity</th>
                  <th>Status</th>
                  {/*<th>Priority</th>*/}
                  <th style={{width:"100px"}}>Action</th>
                   </tr>
                  </thead>
                  {(lasturrl ==='product-outstock')?
                  <>
                   <tbody>{this.productlist_outstock()}</tbody>
                   </>:<>
                  <tbody>{this.productlist()}</tbody>
                  </>
                }
                  
                </Table>
				      </div>
              {(lasturrl ==='product-outstock')?
                  <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord_out}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
                 :
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
                }
                
              </div>

            </div> 
        </div>  
      </div>
      <div className="confirm-action">
          <p>Are you sure! you want to {this.state.selectedStatus} the Product?</p>
          <a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
          <a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		  </div>  
    </div>
    );
  }
}


export default List;