import React, { Component } from 'react';
import Header from '../Layouts/Header';

import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import {apiUrl } from "../Config/Config";
import Axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom'

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class View extends Component {
  
  constructor(props)
  {
    super(props); 
    //const colorid = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
    const colorid = (typeof this.props.params.ID === 'undefined') ? '' : this.props.params.ID;
    // console.log(colorid,'colorid')
    this.state = {      
      colorid: colorid,
      colorlistview:'',
    };

    var qs = require('qs');
    var postObject = {             
    id   :colorid
    };
   
    Axios.post(apiUrl+"color/view",qs.stringify(postObject)).then(res => {
      if(res.data.status === 'success'){
        console.log( res.data.colorlistview)
          this.setState({ colorlistview: res.data.colorlistview[0]});

      }
    });  
  }

   
   componentDidMount() {
     document.title = PageTitle('Product View');
   }

   componentDidUpdate(prevProps,prevState){

   }
   componentWillReceiveProps(Props){
      if(Props.colorlistview!==this.state.colorlistview){
        if(Props.productlistview[0].status == "success"){
          this.setState({colorlistview: Props.colorlistview[0].colorlistview[0]}) 
        }
      }

    
   }


  
  render() {
    var color_code;
    color_code = this.state.colorlistview.color_code;
    const navigate = this.props.navigate;
    return (
      <div className="wrapper"> 
 
      <Header />
      <MenuSidebar currentpage="Color" />  
      
      <div className="content"> 
        <div className="content-wrapper">
            <div className="content-body">
            <div class="content-wrapper-before"></div>
            <div className="form-wrapper leaa-admin-head card">
                <div className="back-btn-lux">
                  <a onClick={() => navigate(-1)}>Back</a>    
                </div> 
                <div className="title">
                  <h3>Color View</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Color Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.colorlistview.name}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b> Color<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p><div style={{background:color_code,width:'20%',justifyContent:'center',alignItems: 'center'}} >.</div></p>
                        </div>
                      </div>
                      

                        <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Status<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                        <p>{(this.state.colorlistview.status=='A')?'Active':'Inactive'}</p>
                        </div>
                      </div>
                      </div>
                      </div>
               
                    {/* <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div> */}
              </div>            
               
              
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

export default (withRouter(View));