import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import moment from 'moment';
import axios from 'axios';
import $ from 'jquery';
import { lang } from '../Helpers/lang';
import Header from '../Layouts/Header';
import { apiUrl,productURL,invoiceURL} from'../Config/Config';

import parser from  'html-react-parser';

import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";

import MenuSidebar from '../Layouts/MenuSidebar';
import { baseUrl } from '../Config/Config';

class List extends Component {
  
  constructor(props)
  {
     super(props); 
     this.inputRef = React.createRef();
      this.state = {
          specialties:'',
          activePage: 1,
          totalRecord: '',
          search_all:'',
          selectedStatus:'',
          selectid:'',
          data_status:'',
          bt_data: [],
          selectstatusid:'',
          products:[],
          editMode: false,
          editedName: '',
          editItemId: null,
          paymenthistorylist:'',
          totalRecord_out:""
         
      };
      
    
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);
      this.handleNameClick = this.handleNameClick.bind(this);
      this.handleNameChange = this.handleNameChange.bind(this);
      this.handleSaveName = this.handleSaveName.bind(this);
     
    }

   
   componentDidMount() {
    this.listloadedData()
    // if (this.state.editMode && this.inputRef.current) {
    //   this.inputRef.current.focus();
    // }
   }
   componentDidUpdate(prevProps, prevState) {
    if (this.state.editMode && this.state.editItemId !== prevState.editItemId) {
      this.inputRef.focus();
    }
  }
  onChangeHandler = (id,event) =>{
    let reader = new FileReader();
    const file = event.target.files[0];
    const filename = file.name;
    reader.onloadend = () => {
    this.setState({
      invoice_name: file.name,
      invoice_doc: file
    });
    };

    reader.readAsDataURL(file);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    var qs = require('qs');
      var postObject = {             
        invoice_name : file.name,
        invoice            : file,
        product_id         : id
    };

    let formData = new FormData();

    for(let k in postObject) {

    formData.append(k, postObject[k]);
    }
    
    axios.post(apiUrl+"product/upload_invoice",formData,config).then(res => {
    if(res.data.status === 'success'){
      $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
         setTimeout(
        function() {
          $('.success_message').html('');
        }
        .bind(this),
        3000
        );
    }else if(res.data.status === 'error'){
      this.setState({  cardetailloading: false});
        $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
         setTimeout(
        function() {
          $('.success_message').html('');
        }
        .bind(this),
        5000
        );
      }
     }); 
}
  listloadedData(postobject=''){ 
    var qs = require('qs');
    if(postobject!=''){
      var postobject = postobject
    }else{
     
      var postobject = {
      activePage: 1,
      search_all: '',
      admin_id: localStorage.getItem('admin_id')  
      };
    }
 
   axios.post(apiUrl+"product/paymenthistorylist",qs.stringify(postobject)).then(res => {
     
     if(res.data.status === 'success'){
         this.setState({  paymenthistorylist: res.data, totalRecord: res.data.paymenthistorylist.length,totalRecord_out:res.data.paymenthistorylist_completed.length}, function() {
            this.paymenthistorylist();
         });
     }
   }); 

  }

handleNameClick = (id,name) => {
  this.setState({
    editMode: true,
    editedName: name,
    editItemId: id,
  });
};

handleNameChange = (e) => {
  this.setState({
    editedName: e.target.value,
  });
};

handleSaveName = (id) => {
  console.log(this.state.editedName,'nameedited')
  
  
  var admin_id = localStorage.getItem("admin_id");
  var postObject = {
    id  : id,
    admin_id  : admin_id,
    specialtiesname:  this.state.editedName,
  };
  var qs = require('qs');
  axios.post(apiUrl+"specialties/update", qs.stringify(postObject)).then(res => {
    if(res.data.status == "success"){
      $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
        this.listloadData();
    }else{
      $('.success_message').html('<div class="status_sucess"><h3>Something went wrong</h3></div>');
    }

    setTimeout(
      function() {
        $('.success_message').html('');
      }
      .bind(this),
      3000
    );
  });
  // Update state and exit edit mode
  this.setState({
    editMode: false,
    editedName: '',
  });
};
deletespecialties(){
  var qs = require('qs');
  var admin_id = localStorage.getItem("admin_id");
  var postObject = {
    id  : this.state.selectid,
    admin_id  : admin_id
  };
      axios.post(apiUrl+"specialties/delete",qs.stringify(postObject)).then(res => {
          if(res.data.status === 'success'){
                $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
                setTimeout(
                function() {
                $('.success_message').html('');
                }
                  .bind(this),
                3000
                );
                this.listloadData()
             
      }   
  
}); 

}
confirmPopup = (id, status) => {
  var status = status;
  if(status == 'inactive'){
    status = "Active";
  }else if(status == 'active'){
    status = "Inactive";
  }else{
    status="Delete";
  }
 
  this.setState({selectid: id, selectedStatus: status})	
  $('.confirm-action').addClass('show');		
}
paymenthistorylist_completed() {
    var paymenthistorylist = this.state.paymenthistorylist;
   // console.log("paymenthistorylistpaymenthistorylist",paymenthistorylist);
    if (paymenthistorylist != "undefined" && paymenthistorylist != null) {
      if (paymenthistorylist.status == "success") {
        if (Object.keys(paymenthistorylist).length > 0) {
          const helperlistDetails = paymenthistorylist.paymenthistorylist_completed.map(
            (paymenthistorylist, Index) => {

              var current = this;

            let sno = Index+1;
            var istyle = { width: 100};
            var paid_status = paymenthistorylist.paid_status;
            var order_status = paymenthistorylist.order_status;
            var payment_type = paymenthistorylist.payment_type == "onetime" ? "One Time" : paymenthistorylist.payment_type;
            var payment_plan = paymenthistorylist.payment_type == "onetime" ?  paymenthistorylist.payment_type = 'N/A' : paymenthistorylist.payment_type;

            var username =  paymenthistorylist.username;
            var email = paymenthistorylist.email;
            var mobileno = paymenthistorylist.mobileno;

            var Product = paymenthistorylist.product_name == null ?  paymenthistorylist.product_name = 'N/A' : paymenthistorylist.product_name;
            var Category = paymenthistorylist.category_search_name == null ?  paymenthistorylist.category_search_name = 'N/A' : paymenthistorylist.category_search_name;
           
           

            var paymenttype;
            // if(payment_plan && payment_plan!== null && payment_plan!== ''){
            //   //paymenttype = payment_type.toUpperCase();
            //   paymenttype = String(payment_plan).charAt(0).toUpperCase() + String(payment_plan).slice(1);
            //   //payment_type.toUpperCase();
            // }else{
            //   paymenttype = 'N/A';
            // }

             let catImg = '';
             let preview = ''; 

            if (paymenthistorylist.product_image!== null && paymenthistorylist.product_image!== '') {
            catImg = productURL + "/" + paymenthistorylist.product_image;
          preview = <img className="img_class" src={catImg} alt="" />;
        }

        var lasturrl = window.location.href.substr(window.location.href.lastIndexOf('/') +1);
          return (
              <tr key={paymenthistorylist.id}>
              <td>{sno}</td>
              <td>{username}</td>
              <td>{mobileno}</td>
              <td>{paymenthistorylist.product_quantity}</td>
              <td>${paymenthistorylist.original_amount}</td>
              <td>{Product } ({Category})</td>
              {/* <td>{preview}</td>
              */}
              
              
              
              {/* <td>{paid_status.charAt(0).toUpperCase() + paid_status.slice(1)}</td> */}
              {/* <td>{payment_plan.charAt(0).toUpperCase() + payment_plan.slice(1)}</td> */}
              <td>{order_status.charAt(0).toUpperCase() + order_status.slice(1)}</td>
               
              <td>{moment(paymenthistorylist.created_date).format('MMM DD Y hh:mm A')}</td>
              
              <td className="actiongroup">
                        <Link to={{ pathname: '/payment-completed-view/'+paymenthistorylist.id}} className="" title="View">  <i className="fa ft-eye" aria-hidden="true"></i></Link>
                      </td>
                </tr>
                
              );
            }
          );
          return helperlistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="13" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }else {
      return (
        <tr>
          <td colspan="13" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
paymenthistorylist() {
    var paymenthistorylist = this.state.paymenthistorylist;
    if (paymenthistorylist != "undefined" && paymenthistorylist != null) {
      if (paymenthistorylist.status == "success") {
        if (Object.keys(paymenthistorylist).length > 0) {
          const helperlistDetails = paymenthistorylist.paymenthistorylist.map(
            (paymenthistorylist, Index) => {

              var current = this;

            let sno = Index+1;
            var istyle = { width: 100};
            var paid_status = paymenthistorylist.paid_status;
            var order_status = paymenthistorylist.order_status;
            var payment_type = paymenthistorylist.payment_type == "onetime" ? "One Time" : paymenthistorylist.payment_type;
            var payment_plan = paymenthistorylist.payment_type;

            var username =  paymenthistorylist.username;
            var email = paymenthistorylist.email;
            var mobileno = paymenthistorylist.mobileno;

            var Product = paymenthistorylist.product_name == null ?  paymenthistorylist.product_name = 'N/A' : paymenthistorylist.product_name;
            var Category = paymenthistorylist.category_search_name == null ?  paymenthistorylist.category_search_name = 'N/A' : paymenthistorylist.category_search_name;
           
           

            var paymenttype;
            if(payment_type && payment_type!== null && payment_type!== ''){
              //paymenttype = payment_type.toUpperCase();
              paymenttype = String(payment_type).charAt(0).toUpperCase() + String(payment_type).slice(1);
              //payment_type.toUpperCase();
            }else{
              paymenttype = 'N/A';
            }

             let catImg = '';
             let preview = ''; 

            if (paymenthistorylist.product_image!== null && paymenthistorylist.product_image!== '') {
            catImg = productURL + "/" + paymenthistorylist.product_image;
          preview = <img className="img_class" src={catImg} alt="" />;
        }
           
         
          return (
                <tr key={paymenthistorylist.id}>
          <td>{sno}</td>
      
          <td>{username}</td>
          <td>{mobileno}</td>
          <td>{paymenthistorylist.count}</td>
          <td>${paymenthistorylist.amount}</td>
        
           {/* <td>
           <select className="prod-list-pay" onChange={current.handlestatusupdateType}  > 
                <option   data-key="">Search by order status</option>
                <option selected={order_status == "Booked"} id={paymenthistorylist.id} data-key="Booked">Booked</option>
                <option selected={order_status == "Assigned"} id={paymenthistorylist.id} data-key="Assigned">Assigned</option>
                <option selected={order_status == "Cancelled"} id={paymenthistorylist.id} data-key="Cancelled">Cancelled</option>
                <option selected={order_status == "Completed"} id={paymenthistorylist.id} data-key="Completed">Completed</option>
                </select>
                
            </td> */}
          <td>{moment(paymenthistorylist.created_date).format('MMM DD Y hh:mm A')}</td>
          
                  <td className="actiongroup paymenthistorygrp">
                    <Link to={{ pathname: '/payment-view/'+paymenthistorylist.order_id}} className="" title="View">  <i className="fa ft-eye" aria-hidden="true"></i></Link>
                    {(order_status == 'Completed' && paymenthistorylist.order_invoice== null)?<><input id="fileupload" type="file" name="fileupload"  className='fileupload-upload' onChange={this.onChangeHandler.bind(this,paymenthistorylist.id)} /></>:''}
                    {(order_status == 'Completed' && paymenthistorylist.order_invoice !== null)?<>  <Link onClick={this.downloadinvoice.bind(this,paymenthistorylist.order_invoice)} className="" title="Export"><i className="fa fa-download" aria-hidden="true"></i></Link></>:''}
                  </td>
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="12" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }else {
      return (
        <tr>
          <td colspan="12" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
 
  handlePageChange(pageNumber) {
    this.setState({activePage: pageNumber});
    var qs = require('qs');
    var postobject = {
      activePage: pageNumber,
      search_all: this.state.search_all,
      admin_id: localStorage.getItem('admin_id')  
    };
   this.listloadData(postobject)
  }

  handleInputChange(event) {
  const {name, value} = event.target;  
  var postObject = {             
    activePage   : this.state.activePage,
    search_all : value,
    admin_id: localStorage.getItem('admin_id')           
  };    
  this.setState({
    [name]: value
  },function(){
    this.listloadData(postObject)
  });
    

}
 handleFormSubmit = () => {
      
        this.setState({Loading:true});
        var qs = require('qs');
        const formPayload = this.state;
         
        var postObject = {             
          activePage   : this.state.activePage,
          search_all : this.state.search_all,
          admin_id: localStorage.getItem('admin_id')           
        };
        this.listloadData(postObject)
      
  } 

   componentWillReceiveProps(Props){
   }

activatespecialtieslist(){
    console.log(this.state.selectedStatus,'statusssssssssss');
	let stat = ''
		if(this.state.selectedStatus ==='Inactive'){
			stat = "I";
		}else{
			stat = "A";
		}  

        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          id  : this.state.selectid,
		      status: stat
        };
      axios.post(apiUrl+"specialties/changestatus", qs.stringify(postObject)).then(res => {
        if(res.data.status == "success"){
          $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
           this.listloadData()
           setTimeout(
            function() {
              $('.success_message').html('');
            }
            .bind(this),
            3000
            );
        }else{
          $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
        }
      });
}

Confirmstatus = (confirmstatus) => {
  if(confirmstatus == 'yes'){
    if(this.state.selectedStatus=="Delete"){
      this.deletespecialties()
    }else{
      this.activatespecialtieslist();
    }
    
    $('.confirm-action').removeClass('show');
  }else{
    $('.confirm-action').removeClass('show');	
  }
    
}
exportdata(){
	let stat = '';		
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id")
        };
        axios.post(apiUrl+"user/export",qs.stringify(postObject)).then(res => {      
          if(res.data.status === 'success'){
            var url = res.data.url;
            var cleanurl = url.replace(/([^:]\/)\/+/g, "$1");
            window.open(cleanurl, '_blank', 'noreferrer');
            // window.location.reload();
          }
        }); 
      
}

  
  render()  {
    const datas = this.state.bt_data;
    const {paymenttypelist,start_date,end_date} = this.state;
    var current = this;

    var lasturrl = window.location.href.substr(window.location.href.lastIndexOf('/') +1);
		const columns = [
			{dataField: 'id', text: 'ID', hidden: true}, 
			{dataField: 'sno', text: 'S.No', editable: false}, 
			{ dataField: 'name', text: 'Name', editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex, columnIndexInHeaders) => {
        return (
          <input
            {...editorProps}
            value={value}
            onChange={(e) => {
              const updatedData = [...datas];
              updatedData[rowIndex][column.dataField] = e.target.value;
              this.setState({ bt_data: updatedData });
            }}
          />
        );
      } },
			{dataField: 'status', text: 'Status', editable: false},
			{dataField: 'action', text: 'Actions', editable: false}
		];

		let mystyle = {
		    color: "white",
		    backgroundColor: "#3E3E3C",
		    padding: "10px",		  
			minWidth: "49px",
			height: "37px",
			lineHeight: "0px",
			textTransform: "capitalize"
	
		};

        return (
            <div className="wrapper"> 
       
          <Header />
      
         {(lasturrl ==='payment-completed-history')?
          <>
           <MenuSidebar currentpage="payment-completed-history" />
           </>:<>
           <MenuSidebar currentpage="payment-history" />
          </>
        }
            
            
            <div className="content"> 
              <div className="content-wrapper">
              <div class="content-wrapper-before"></div>
              <div className="car-listing-wrap leaa-admin-head card">
      
                    <div className="listing-header">
                      <div className="title">
                      {(lasturrl ==='payment-completed-history')?                  
                         <h3>{this.state.totalRecord_out} Completed Orders</h3>:<h3>{this.state.totalRecord} Order History</h3>                  
                      }                 
                      </div>
      
                      {(lasturrl ==='payment-completed-history')?
                        <>         
                        </>:<>
                      {/* 	<div className="form-group">
                              <select  onChange={current.handleClickSelecetType}  value={this.state.search_type}> 
                              <option data-key="">Search by order status</option>
                              <option data-key="Booked">Booked</option>
                              <option data-key="Assigned">Assigned</option>
                              <option data-key="Cancelled">Cancelled</option>
                              <option data-key="Completed">Completed</option>
                              </select>
                              <div className="errorassign_paymenttype"></div>
                          </div> */}
                        </>
                      }
      
                      {/*(lasturrl ==='payment-completed-history')?
                        <>                   
                        </>:<>
                         <div className="search-list">
                         <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                          <div className="search-inner">
                            <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                           <button className="" type="submit">Submit</button>
                          <div className="erroremail"></div>
                        </div>
                       </form>
                      </div>              
                      </>
                      */}
                      
                   </div> 
                   
                   
                      
                     {/*  <div className="search-list-date">
                      <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >		
                          <div className="start-date">
                              <label>Start Date : </label>
                              <DatePicker
                              name="Start Date"
                              selected={this.state.start_date}
                              onChange={this.onChangedatestart}
                              dateFormat="MM/dd/yyy"
                              showMonthDropdown
                              showYearDropdown
                              value={this.state.start_date}
                              />
                          </div>
                          <div className="end-date">
                              <label>End Date : </label>
                              <DatePicker
                               name="End Date"
                              selected={this.state.end_date}
                              onChange={this.onChangedateend}
                              dateFormat="MM/dd/yyy"
                              showMonthDropdown
                              showYearDropdown
                              value={this.state.end_date}
                              />                    
                          </div>
                          <button className="serach-btn" type="submit"><i class="fa fa-search"></i></button>
                       
                      </form>
                      <button className="refresh-btn" onClick={() => {this.onClose_order();}} type="submit"><i class="fa fa-refresh"></i></button> 
                      </div> */}
                      
                   
      
                  <div className="success_message"></div>
      
                    <div className="car-listing-row">
                    <div className="overflow">
                      <Table striped bordered hover>
                        
                        
                        {(lasturrl ==='payment-completed-history')?
                        <>
                            <thead>
                                <tr>
                                <th>S.No</th>
                                <th>User Name</th>
                                <th>User Contact</th>
                                <th>Purchased Items</th>
                                <th>Amount</th>
                                <th>Category</th>
                                <th>Status</th>
                                <th>Ordered on</th>
                                <th>Action</th>
                                </tr>
                        </thead>
                          </>:<>
                          <thead>
                              <tr>
                              <th>S.No</th>
                              <th>User Name</th>
                              <th>User Contact</th>
                              <th>Purchased Items</th>
                             
                              
                              <th>Amount</th>
                              <th>Ordered on</th>
                              <th>Action</th>
                         </tr>
                        </thead>
                          </>
                         }
                        {(lasturrl ==='payment-completed-history')?
                        <>
                         <tbody>{this.paymenthistorylist_completed()}</tbody>
                         </>:<>
                         <tbody>{this.paymenthistorylist()}</tbody>
                        </>
                      }
      
                        
                      </Table>
                      </div>
      
                      {(lasturrl ==='payment-completed-history')?
                        <>
                         <div className="text-center">

                         <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.totalRecord_out}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange.bind(this)}
                      />
                       
                      </div>
                         </>:<>
                         <div className="text-center">
                        <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.totalRecord}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange.bind(this)}
                        />
                      </div>
                        </>
                      }
                      
                    </div>
      
                  </div> 
              </div>  
            </div>
            
          </div>
          );
  }
}

export default List;