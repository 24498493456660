import React, { Component } from 'react';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth,scrollToTop } from "../Helpers/SettingHelper";
import VariantRow from "./VariantRow";
import { apiUrl,productURL,mediaUrl} from'../Config/Config';
import axios from 'axios';

import "react-datepicker/dist/react-datepicker.css";
import DatePicker from 'react-datepicker';
import moment from 'moment/moment';
import { Scrollbars } from 'react-custom-scrollbars';

import Addembedinput from './Addembedinput';
import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';
import MyEditor from '../Membership/MyEditor';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useParams, useNavigate } from 'react-router-dom';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };

const discount = [
	{ value: 'flat', label: 'Flat' },
	{ value: 'percentage', label: 'Percentage' }
  ];
  const lasturrl = window.location.href.substr(window.location.href.lastIndexOf('/') +1);
class Edit extends Component {
	fileObj = [];
    fileArray = [];
    imageArray = [];
	constructor(props)
	{ 
		CheckAuth();
		super(props);	
		const productId = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
		this.state = {
           // domain_id:'',
            productId: productId,
			domain_id:'',
			product_name:'',
			short_name:'',			
			product_priority:'',
			product_color:'',
			product_quantity:'',
			price:'',
			description:'',
			short_description:'',
			product_image:'',
			fileName: [],
			thumbDefault: '',
			thumbtypeimg: [],	
			image: [],
			selectedCategory:'',
			categoryvalue:'',
			categorylist:[],
			status:'',
			Loading:false,
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			onetime_checked:false,
			recurring_checked:false,
			recurring:0,
			subscription_plan:'',
			subs_plan_final_all:'',
			subscriptionlist:[],
			imagelist:[],
			sublist_final:[],
			subscription_plan_price:'',
			subscription_plan_id:'',
			sublist_data:'',
			enableuploadgalley: false,
			galleryimagelist:'',
			selectedimages:[],
			galleryimg: 'No',	
			free_shipping:0,
			free_shipping_checked:false,
			goods_type:'',
			selectedDimension:'',
			productdimensionvalue:'',
			dimensionlist:[],
			discount_price:'',
			selecteddisctype:'',
			discounttype:'',
			discount_gprice:'',
			selectedgdisctype:'',
			gdiscounttype:'',
			startDate:'',
			endDate:'',
			selectedColor:'',
			colornamelist:[],
			store_id: '',
			storenamelist:[],
			unlimited_quantity: 'no',
			catenamelist:[],
			catevarientlist:[],
			selectedvariantcatevalue:"",
			variantlist:[],
			variantcatenamelist:[],
			catecolorvarientlist:[],
			catesizevarientlist:[],
			cateweightvarientlist:[],
			selectedvariantcatevalue:"",
			rows: [
				{
				  selectedVariantOption: null,
				  selectedVariantcateOption: null,
				  addonprice: "",
				},
			  ],
		};
			
		var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl+'variants/getvariantnamelist?admin_id='+admin_id)
			.then(res => {
				if(res.data.status == 'success'){
					const catenamelist = res.data.catenamelist;
					this.setState({
						catenamelist:catenamelist
					  });
					 const sizeObject = catenamelist.find(item => item.label == "Size");
					 const sizeId = sizeObject.value;
					  axios
						.get(`${apiUrl}variants/getvariantcatelist?admin_id=${admin_id}&catid=${sizeId}`)
						.then((res) => {
							if (res.data.status === "success") {
								this.setState({
									catesizevarientlist: res.data.catevarientlist
								});
								
							}
						})
						.catch((err) => {
							console.error(err);
						});
				const colorList = catenamelist.filter(item => item.label == "Color"); 
				const colorId = colorList[0].value; 
				axios
				.get(`${apiUrl}variants/getvariantcatelist?admin_id=${admin_id}&catid=${colorId}`)
				.then((res) => {
					if (res.data.status === "success") {
						this.setState({
							catecolorvarientlist: res.data.catevarientlist
						});
						
					}
				})
				.catch((err) => {
					console.error(err);
				});
				const weightList = catenamelist.filter(item => item.label == "Weight"); 
				const weightId = weightList[0].value; 
				axios
				.get(`${apiUrl}variants/getvariantcatelist?admin_id=${admin_id}&catid=${weightId}`)
				.then((res) => {
					if (res.data.status === "success") {
						this.setState({
							cateweightvarientlist: res.data.catevarientlist
						});
						
					}
				})
				.catch((err) => {
					console.error(err);
				});



				axios.get(apiUrl+"product/listdetail?id="+productId).then(res => {

					if(res.data.status === 'success'){  
								const formdata = res.data.productlist;
								
								var lasturrl = window.location.href.substr(window.location.href.lastIndexOf('/') +1);
						
						
								
						
										this.setState({Loading:false});
						
											
						
						
												if(formdata.sale_start!= null && formdata.sale_end!= null){
													var valid_from = new Date(formdata.sale_start);
													this.setState({startDate:valid_from});
													var valid_to = new Date(formdata.sale_end);
													this.setState({endDate:valid_to});
												}
												
												if(formdata.status === 'A'){
													this.setState({selectedOption:{value: 'active', label: 'Active'}});
												}else{
													this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
						
												}
												this.setState({unlimited_quantity:formdata.unlimited_qty})
												
												if(formdata.discount === 'flat'){
													this.setState({selecteddisctype:{value: 'flat', label: 'Flat'}});
												}else{
													this.setState({selecteddisctype:{value: 'percentage', label: 'Percentage'}});
						
												}
												if(formdata.giftdiscounttype === 'flat'){
													this.setState({selectedgdisctype:{value: 'flat', label: 'Flat'}});
												}else{
													this.setState({selectedgdisctype:{value: 'percentage', label: 'Percentage'}});
						
												}
						
												if(formdata.onetime_product === '1'){
													this.setState({onetime_checked: true});
													this.setState({onetime: formdata.onetime_product});
												}else{
													this.setState({onetime_checked: false});
													this.setState({onetime: formdata.onetime_product});
												}
						
												if(formdata.recurring_product === '1'){
												this.setState({recurring_checked: true});
												this.setState({recurring: formdata.recurring_product});
												}else{
												this.setState({recurring_checked: false});
												this.setState({recurring: formdata.recurring_product});
												}
												
												
		
												this.setState({ 
													variantlist: res.data.variantlist,
													
												  }, () => {
													
													const updatedRows = this.state.variantlist.map((variant) => {
														const imageUrl = mediaUrl+'/'+'product_variants'+'/'+`${variant.image}`;
													  const selectedsizeCategory = this.state.catesizevarientlist.find(
														(category) => category.value === variant.size_variant_id
													  );
													  const selectedcateweight = this.state.cateweightvarientlist.find(
														(categorys) => categorys.label === variant.weight
													  );

													  const selectedcolorCategory = this.state.catecolorvarientlist.find(
														(category) => category.value === variant.color_variant_id
													  );
													  
													
													  return {
														selectedsizeVariantcateOption: selectedsizeCategory || null, // Set the matching category
														selectedcolorVariantOption: selectedcolorCategory || null,
														addonprice: variant.variant_item_price, 
														selectedweightVariantOption: selectedcateweight, 
														weightValue: variant.weight_value, 
														addonprice: variant.price, 
														variant_quantity: variant.quantity, 
														variant_image: {
															file: null, 
															preview: imageUrl, 
														  },
														  
													  };  
													});
												  
													
													this.setState({ rows: updatedRows });
												  });
												this.setState({product_name:formdata.product_name, store_id :res.data.productlist.store_id});
												this.setState({short_name:formdata.short_name});						
												this.setState({description: formdata.product_description});	
												if(formdata.short_description!==null){
													this.setState({short_description: formdata.short_description});
												}else{
													this.setState({short_description:''})
												}			
												
												this.setState({product_priority: formdata.product_priority});
												this.setState({product_image: formdata.product_image});
												this.setState({productId: formdata.product_id});
												this.setState({product_color: formdata.product_color});
												this.setState({product_quantity: formdata.product_quantity});
												this.setState({price: formdata.price});
												this.setState({free_shipping: formdata.free_shipping});
												this.setState({goods_type: formdata.goods_type});
												if( parseInt(formdata.discount_price)!== 0){
													this.setState({discount_price: formdata.discount_price});
												}else{
													this.setState({discount_price: ''});
												}
												if( parseInt(formdata.giftdiscount_price)!== 0){
													this.setState({discount_gprice: formdata.giftdiscount_price});
												}else{
													this.setState({discount_gprice: ''});
												}
																
						
												if(formdata.free_shipping === '1'){
												this.setState({free_shipping_checked: true});
												this.setState({free_shipping: formdata.free_shipping});
												}else{
												this.setState({free_shipping_checked: false});
												this.setState({free_shipping: formdata.free_shipping});
												}
												if(formdata.product_dimension!==''){
													var dimensionlable = formdata.product_dimension.concat(" ", '(W X H)');
												this.setState({selectedDimension: {value: formdata.product_dimension, label: dimensionlable},productdimensionvalue: formdata.product_dimension});
												}
												
						
												if(res.data.selectedcategory[0]!==null &&  res.data.selectedcategory.length > 0){
													if(res.data.selectedcategory[0].value!== '' && res.data.selectedcategory[0].value!== null){
														this.setState({ selectedCategory : res.data.selectedcategory[0]})
														localStorage.setItem("original_selected_category",res.data.selectedcategory[0].value)
														}
												}								
						
												
												if(formdata.product_color!==null && Object.keys(res.data.selectedcolor).length > 0 ){
													if(res.data.selectedcolor.value!== '' && res.data.selectedcolor.value!== null)
													{
														this.setState({ selectedColor : res.data.selectedcolor})
													}
						
												}else{
													this.setState({ selectedColor :''})
												}
						
						
											if(res.data.productlist.length > 0){
											if(res.data.sublist_final!== '' && res.data.sublist_final!== null){
												this.setState({ sublist_final : res.data.sublist_final})
													let subs_plan = [];
													const  subscrlist = res.data.sublist_final.map((subscriptionlist, index) =>{
													setTimeout(
														function() {
														localStorage.setItem('subs_plan_'+index, JSON.stringify({subscription_plan_price: subscriptionlist.subscription_price,subscription_plan_id:subscriptionlist.subscription_id}));
														
														}
														.bind(this),
														500
													);
														  
												 });
												}
											}else{
												this.setState({ sublist_final : ''})
											}
						
											if(res.data.product_image!== ''){
												this.setState({imageSets:res.data.product_image})
											}else{
												this.setState({imageSets:''})
											}
											
											if(res.data.productlist.length > 0){
												if(res.data.imagelist!== '' && res.data.imagelist!== null){
													this.setState({ imagelist : res.data.imagelist})
												}
											}else{
												this.setState({ imagelist : ''})
											}
										
									
								
					} else {
		
					}
					
				});
				}else{
				//console.log("test")
				}
			});
			
			

		this.handleInputChange = this.handleInputChange.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
	    this.handleChange = this.handleChange.bind(this);
		this.handleChangeVariant = this.handleChangeVariant.bind(this);

	    localStorage.removeItem("original_selected_category")

    }

	handleChangeColor = selectedColor => {
		this.setState({ selectedColor});
		this.setState({ colorvalue : selectedColor.value });
		this.setState({ product_color : selectedColor.value });
 	}
	
 handleChangeVariant = (r,selOption) => {
	if(r =='variant'){
	   this.setState({selectedVariantOption:selOption})
	   this.setState({  selectedvariantvalue : selOption.value});
	   this.handlevariantcateChange(selOption.value);
   } else if(r == 'variantcate') {
	   this.setState({selectedVariantcateOption:selOption})
	   this.setState({  selectedvariantcatevalue : selOption.value});
   }
};

// Assuming `apiUrl` and `productId` are already defined.



  
	  onChangeHandler=event=>{
   /* let reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {		
		
      this.setState({
        productimage_name: file.name,
        product_image_preview: reader.result,
        image: file
      });
	 
		
    };

    reader.readAsDataURL(file);*/
    this.setState({ image: event.target.files })
  }

  onThumbChanged = (e) => {	
    this.setState({
      thumbDefault: e.currentTarget.value
      });
	  
  }


  	 handleChangeCategory = selectedCategory => {

			this.setState({ selectedCategory});
		    this.setState({ categoryvalue : selectedCategory.value });
	 }


	 handleChangeDimension = selectedDimension => {

		this.setState({ selectedDimension});
		this.setState({ productdimensionvalue : selectedDimension.value });
 }

	  handleClickSelecetgoods = (event) => {
		const selectedIndex = event.target.options.selectedIndex;
		var keys = event.target.options[selectedIndex].getAttribute('data-key');

		this.setState({
				goods_type: keys,	
		});

	}
  
	

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {
        let subs_plan = [];
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });

      if(name === 'onetime'){
      	 this.setState({onetime_checked: !this.state.onetime_checked});
      	 if(!$(this).is(':checked')){
					 this.setState({onetime: 1});
				}else{
					 this.setState({onetime: 0});
				}
      }

      if(name === 'recurring'){
      	 this.setState({recurring_checked: !this.state.recurring_checked});
      	 if(!$(this).is(':checked')){
					 this.setState({recurring: 1});
				}else{
					 this.setState({recurring: 0});
				}
      }


       if(name === 'subscription_plan[]'){
      	 
      	  var ref = $("#"+event.target.id)[0].attributes["data-ref"].value;
      		
         subs_plan.push({subscription_plan_price: event.target.value,subscription_plan_id:event.target.id});
        				
			localStorage.setItem('subs_plan_'+ref, JSON.stringify(subs_plan));

      }

       if(name === 'free_shipping'){
      	 this.setState({free_shipping_checked: !this.state.free_shipping_checked});
      	 if(!$(this).is(':checked')){
					 this.setState({free_shipping: 1});
				}else{
					 this.setState({free_shipping: 0});
				}
      }

   
		
    }

     handleInputChange_sub(i,id,event) {
     	let subs_plan = [];
     	  /* const arrayList = [];
   		   arrayList.push({ [id]: event.target.value })
  	       this.setState({sublist_data: arrayList}); */

  	         var ref = $("#"+event.target.id)[0].attributes["data-ref"].value;
      		
             subs_plan.push({subscription_plan_price: event.target.value,subscription_plan_id:event.target.id});
        				
			localStorage.setItem('subs_plan_'+ref, JSON.stringify(subs_plan));
    }


    
	componentDidMount() {
		var admin_id = localStorage.getItem('admin_id');
		
		this.storelistloadedData();
		document.title = PageTitle('Product Edit');

	
			axios.get(apiUrl+'subscription/getsubscriptionlist?admin_id='+admin_id)
			
			.then(res => {
				if(res.data.status == 'success'){
				
					this.setState({
						subscriptionlist: res.data.subscriptionlist
					})

			  var subscriptionlist = res.data.subscriptionlist;
				subscriptionlist.forEach(function(data,index) {
				localStorage.removeItem('subs_plan_'+index);
				localStorage.removeItem('subs_plan_all');
				});
				}else{
				//console.log("test")
				}
			});

			axios.get(apiUrl+'product/getcolornamelist?admin_id='+admin_id)
			
			.then(res => {
				if(res.data.status == 'success'){
				
					this.setState({
						colornamelist: (res.data.colornamelist)
					});
				
				}else{
				//console.log("test")
				}
			});
    }
	// handlevariantcateChange = (catid, index) => {
	// 	const admin_id = localStorage.getItem("admin_id");
	
	// 	axios
	// 		.get(`${apiUrl}variants/getvariantcatelist?admin_id=${admin_id}&catid=${catid}`)
	// 		.then((res) => {
	// 			if (res.data.status === "success") {
	// 				const rows = [...this.state.rows];
	// 				rows[index].catevarientlist = [
	// 					{ value: "", label: "Select Variant" },
	// 					...res.data.catevarientlist,
	// 				];
	// 				this.setState({ rows });
	// 			}
	// 		})
	// 		.catch((err) => {
	// 			console.error(err);
	// 		});
	// };
	storelistloadedData(postobject=''){
		var admin_id = localStorage.getItem("admin_id");
		var accesscode = localStorage.getItem("company_code");  
	
	   axios.get(apiUrl+'product/getstorenamelist?admin_id='+admin_id+'&accesscode='+accesscode).then(res => {
		 if(res.data.status === 'success'){
			this.setState({  storenamelist: [{value: '', label: 'Select Store'}].concat(res.data.storelist)});
		 }else{
			  this.setState({  storelist: ''});
		 }
	   }); 
	  }
	
        handleFormSubmit = () => {

			if(this.validateForm()){ 
				this.setState({Loading:true});
				const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };
					const formPayload = this.state;
					var qs = require('qs');
					var status = '';
				   if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
					status =formPayload.selectedOption.value;
					}else{
					status = formPayload.selectedvalue;
					}

					var assigncategory;
					if(formPayload.selectedCategory !== ''){
					assigncategory =formPayload.selectedCategory.value;
					}else{
					assigncategory =formPayload.categroyvalue;
					}

					var dimension;
					if(formPayload.selectedDimension !== ''){
					   dimension =formPayload.selectedDimension.value;
					}else{
						dimension =formPayload.productdimensionvalue;
					}

					var discounttype;
					if(formPayload.selecteddisctype !== ''){
						discounttype =formPayload.selecteddisctype.value;
					}else{
						discounttype =formPayload.discounttype;
					}
					var gdiscounttype;
					if(formPayload.selectedgdisctype !== ''){
						gdiscounttype =formPayload.selectedgdisctype.value;
					}else{
						gdiscounttype =formPayload.gdiscounttype;
					}
	
					var recurring = '';
					if(this.state.recurring_checked === true){
							recurring = '1';
							
					}else{
							recurring = '0';
					}

					var free_shipping = '';
					if(this.state.free_shipping_checked === true){
							free_shipping = '1';
					}else{
							free_shipping = '0';
					}

			       setTimeout(
						function() {
                     
					if(recurring == '1'){
						var sub_final_all =	localStorage.getItem("subs_plan_all");
					}else if(recurring == '0'){
						var sub_final_all =	null;
					}

					var onetime = '';
					var price ='';
					if(this.state.price !== ''){
						 onetime = '1';
						 price = formPayload.price;

					}else{
						 onetime = '0';
						 price = null;
					}

					var galleryimg = 'No';
					if(Object.keys(formPayload.selectedimages).length >0){
					   galleryimg = 'Yes';
					}

				

					if(formPayload.startDate !== ''){
						var stday = new Date(formPayload.startDate);
					    var startday = moment(stday).format("YYYY-MM-DD HH:mm");
					}else{
						var startday = '';
					}

					if(formPayload.endDate !== ''){
						var enday = new Date(formPayload.endDate);
					    var endday = moment(enday).format("YYYY-MM-DD HH:mm");
					}else{
						var endday = '';
					}

				   var postObject = {
					admin_id : localStorage.getItem("admin_id"),
                    domain_id : formPayload.domain_id,
					product_name: formPayload.product_name,
					short_name:formPayload.short_name,
                    product_priority: formPayload.product_priority,
					product_color: formPayload.product_color,
                    product_quantity: formPayload.product_quantity,
                    price: price,
					product_description: formPayload.description,
					short_description:formPayload.short_description,
					selected_category_id: assigncategory,					
					product_image:formPayload.image,
					status:  status,
					id: formPayload.productId,
					onetime: onetime,
					recurring: recurring,
					free_shipping: free_shipping,
					subs_plan_all: sub_final_all,
					goods_type:formPayload.goods_type,
					removeId: formPayload.removeID,
					product_dimension: dimension,
					discounttype: discounttype,
					gdiscounttype: gdiscounttype,
					discount_price: formPayload.discount_price,
					discount_gprice: formPayload.discount_gprice,
					start_date: startday,
					end_date: endday,
					store_id: this.state.store_id,
					unlimited_quantity:this.state.unlimited_quantity,
					rows: JSON.stringify(this.state.rows)
				};


				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}	


				this.state.rows.forEach((row, index) => {
					if (row.variant_image && row.variant_image.file) {
						formData.append(`rowsimages[${index}][variant_image]`, row.variant_image.file);
					}
					});
				var filesdata;
                if(galleryimg == 'No'){
                    filesdata = formPayload.image;	
                }else{
                	 filesdata = this.state.selectedimages;	
                }  

				  for (var i in filesdata) {
	 			formData.append('images[]',filesdata[i])
					}

				
				var image_sets = formPayload.imageSets;
						
				for(let i in image_sets){

					if(image_sets[i]['id'] ==  'undefined'){
						formData.append('imageSets['+ i + '][id]','null')		
					}else if(image_sets[i]['id'] !==  undefined && image_sets[i]['id'] !==  ''){
						formData.append('imageSets['+ i + '][id]',image_sets[i]['id'])
					}
					formData.append('imageSets['+ i + '][image_name]',image_sets[i]['image_name'])
					formData.getAll('imageSets');
				}	       

			    

				axios.post(apiUrl+"variants/editvarieant",formData,config).then(res => {

					if(res.data.status === "success"){
	
						const formdata = res.data.subscriptionedit;
	
						
							$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
							
   
									setTimeout(
									function() {
										this.props.navigate('/product');
										// $('.success_message').html(this.props.history.push('/color'));
									}
									.bind(this),
									3000
									);
						}else{
							$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
							
							setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							3000
							);
						}
				});
				//this.props.getEditCategory(qs.stringify(postObject));
				}
						.bind(this),
						3000
						);
			}
	}
	handleChangeStore = (event) => {
		const admin_id = localStorage.getItem("admin_id");
		const newStoreId = event.target.value;
		this.setState({ store_id: newStoreId });
		axios.get(apiUrl+'product/getcategoryfilternamelist?admin_id='+admin_id+'&store_id='+newStoreId)	
		.then(res => {
			if(res.data.status == 'success'){
				
				this.setState({
					categorylist: [{value: '', label: 'Select Category'}].concat(res.data.categorynamelist)
				})	
				
			}
		});
	};
	validateForm() {
		//const {admin_company_name,admin_fname} = this.state;
		const {product_name,selectedCategory,short_name,cat_name,product_priority,price,product_quantity,product_color,description,short_description,image,status, selectedDimension,selectedColor} = this.state;
		let errors = {};
      	let formIsValid = true;



      	let subs_plan_final = [];
					  
			      /********To gather the plan price and sent to api**/
		            var admin_id = localStorage.getItem('admin_id');
					axios.get(apiUrl+'subscription/getsubscriptionlist?admin_id='+admin_id)
					.then(res => {
						if(res.data.status == 'success'){
							var subscriptionlist = this.state.subscriptionlist;
							subscriptionlist.forEach(function(data,index) {
								subs_plan_final.push(localStorage.getItem('subs_plan_'+index));
								localStorage.setItem('subs_plan_all',subs_plan_final);
							});
						}
					});
		
		

		if(!selectedCategory ){
			formIsValid = false;
        	$('.errorcat_name').html('<span class="errorspan">Please fill the field</span>');
		}else{
			$('.errorcat_name').html('');
		}
	
		if(!selectedDimension){
			formIsValid = false;
			$('.errorproduct_dimension').html('<span class="errorspan">Please select the dimension</span>');
		}else{
			
			$('.errorproduct_dimension').html('');
		}
	
		if (!product_name) {
			formIsValid = false;
			$('.errorproduct_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(product_name){
			$('.errorproduct_name').html('');
		}
     

		{/*if (!short_name) {
			$('.errorshort_name').html('<span class="errorspan">Please select the field</span>');
		}else if(short_name){
			$('.errorshort_name').html('');
		}*/}
		

		if (!product_priority) {
			formIsValid = false;
			$('.errorproduct_priority').html('<span class="errorspan">Please fill the field</span>');
		}else if(product_priority){
			$('.errorproduct_priority').html('');
		}

		if (!description) {
			formIsValid = false;
			$('.errordescription').html('<span class="errorspan">Please fill the field</span>');
		}else if(description){
			$('.errordescription').html('');
		}

		if (!short_description) {
			formIsValid = false;
			$('.errorshort_description').html('<span class="errorspan">Please fill the field</span>');
		}else if(short_description){
			$('.errorshort_description').html('');
		}

		if (!image) {
			formIsValid = false;
			$('.errorimage').html('<span class="errorspan">Please fill the field</span>');
		}else if(image){
			$('.errorimage').html('');
		}

		if (!selectedColor) {
			formIsValid = false;
			$('.errorproduct_color').html('<span class="errorspan">Please select the color</span>');
		}else if(product_color){
			$('.errorproduct_color').html('');
		}
		if(this.state.unlimited_quantity == 'no'){
			if (!product_quantity) {
				formIsValid = false;
				$('.errorproduct_quantity').html('<span class="errorspan">Please fill the field</span>');
			}else if(product_quantity){
				$('.errorproduct_quantity').html('');
			}
		}
		


		this.setState({
			errors: errors
		});
		return formIsValid;
    }

    


Checkgallery = (indexs,value) =>{
      var imageArr = [...this.state.selectedimages];
      const index = imageArr.findIndex(images => images === indexs);
      if(index > -1) {
     	 imageArr = [...imageArr.slice(0, index), ...imageArr.slice(index + 1)]
      } else {
      imageArr.push(value);
      }
      this.setState({selectedimages: imageArr});
  }
   getGalleryimages() {
    var imageArr = this.state.galleryimagelist;
    if(imageArr!== undefined && imageArr!== null){
      if(Object.keys(imageArr).length > 0) {
         const imageDetails = imageArr.map((image, Index) => {
       		var checked = false;
            return (
                        <div className="asp-col" key={Index}>  
							<input type="checkbox" name="gallery_img" value={Index}  onClick={this.Checkgallery.bind(this,Index,image['name'])} />
							<label>
							<img src={image['url']} alt="" className="" />
							</label>
                        </div>
                     );
    
     });
      return imageDetails;
     }
    } else {
      return (<div className="">No images found</div>);
    }
  }
  opengalleryImages = () => {
		axios.get(apiUrl+'product/getimagelist')
		.then(res => {
			if(res.data.status == 'success'){
				this.setState({galleryimagelist: res.data.imagelist})
			}else{
			   
			}
		});
		this.setState(prevState => ({
			enableuploadgalley: !prevState.enableuploadgalley
		}));
   }

      galleryClose = () => {

   		if(Object.keys(this.state.selectedimages).length > 0){
   			 this.setState({galleryimg: 'Yes'});
   		}
   	    this.setState(prevState => ({
			enableuploadgalley: !prevState.enableuploadgalley
		}));
		$('.cargallery-popup').removeClass('active');
   }


      getafteruploaded(){
	 	var imgArray = this.state.selectedimages;
	 	if(Object.keys(imgArray).length > 0) {
		 	var thumbDefaultArr = this.state.thumbDefault;
			 const imgThumblist = imgArray.map((images, index) => {
			 	console.log(images,'images')
			 			var indicheckFlag = false;
		 				if(thumbDefaultArr != null && thumbDefaultArr == index){
							indicheckFlag = true;
						} 
						  return (
							<li className="thumb" key={index}  >
								<input type="radio" name="sel_img" id="sel_img"  value={index} onClick={this.onThumbChanged} checked={indicheckFlag} />
								<span><img src={productURL+'/'+images}  alt="..." /></span>
								<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
							</li>
							 );
			 });	
				return imgThumblist;					
			/*=======*/
		}

	 }

	 myCallback = (imageSets,imageID) => {
		this.setState({imageSets: imageSets,removeID: imageID});
	};

	handleChangediscount = selecteddisctype => {

		this.setState({ selecteddisctype});
		this.setState({ discounttype : selecteddisctype.value });
   }
   
   handleChangegdiscount = selectedgdisctype => {

	this.setState({ selectedgdisctype});
	this.setState({ gdiscounttype : selectedgdisctype.value });


}

   setStartDate = picked => {	
	this.setState({ startDate: picked });
   };
   setEndDate = picked => {
	this.setState({ endDate: picked });
   };
   handlequntityInputChange = (event) => {
	const { name, value, type, checked } = event.target;
	this.setState({
	  [name]: type === 'radio' ? value : checked ? 'yes' : value,
	});
  };
  handleSelectChange = (index, field, selectedOption) => {
    const rows = [...this.state.rows];
    rows[index][field] = selectedOption;

    // If the field is the category, fetch the variants for this row
    if (field === "selectedVariantOption") {
        this.handlevariantcateChange(selectedOption.value, index);
    }

    this.setState({ rows });
};
  addRow = () => {
    this.setState((prevState) => ({
        rows: [
            ...prevState.rows,
            {
                selectedVariantOption: null,
                selectedVariantcateOption: null,
                addonprice: "",
                catevarientlist: [], // Initialize empty list for variants
            },
        ],
    }));
};


  deleteRow = (index) => {
    const rows = [...this.state.rows];
    rows.splice(index, 1);
    this.setState({ rows });
  };
  handleInputvarieantChange = (index, event) => {
	const { name, value } = event.target;
  
	
	const rows = [...this.state.rows];
  
	
	rows[index][name] = value;
  
	
	this.setState({ rows });
  };
  handleImageVariantChange = (index, event) => {
	const file = event.target.files[0];
		if (file) {
		const rows = [...this.state.rows]; 
		rows[index]["variant_image"] = {
			file: file, 
			preview: URL.createObjectURL(file), 
		}; 
		this.setState({ rows }); 
		}
	
	
  };
  render() {
	const navigate = this.props.navigate;
    var lasturrl = window.location.href.substr(window.location.href.lastIndexOf('/') +1);
  	const categoryArray = this.state.categorylist.map ((categorylist, index) => ({
			id: index,
			name: categorylist,
			}));
			// Dynamically create select list
			let categorys = [];
			categoryArray.map(item =>
			categorys.push({ label: item.name.label, value: item.name.value }),
			);

			const subscrlist = Array.isArray(this.state.sublist_final) 
			? this.state.sublist_final.map((subscriptionlist, index) => {
     	

   	      return(
			<div className="product-subscription-form-left">
   	        	<div className="form-group" key={index+'substrlist'}>
	   	      		 <span>{subscriptionlist.subscription_name+' $'}</span>
	                 <input onChange={this.handleInputChange_sub.bind(this,index,subscriptionlist.subscription_id)} type="text" data-ref={index} id={subscriptionlist.subscription_id} name="sublist_data" defaultValue={subscriptionlist.subscription_price} />
	                 <div className="errorsubscription_plan"></div>
				 </div>
			 </div>
   	      	);
		
    })
  : null; 
               
    
	 const colorArray = this.state.colornamelist.map ((colorlist, index) => ({
		id: index,
		name: colorlist,
		}));
		// Dynamically create select list
		let colors = [];
		colorArray.map(item =>
			colors.push({ label: item.name.label, value: item.name.value }),
		);
	                

	let catImg = '';
	let preview = '';

  	  const {selectedOption,product_image,selectedCategory,selectedColor,imagelist, selectedDimension, selecteddisctype,selectedgdisctype} = this.state;
  	// if ((product_image!== null) && (product_image!== '') && (imagelist =='') ) {
	//     catImg = productURL + "/" + product_image;
	//      console.log("hiidfdfdf",catImg);
	// 	preview = <img className="img_class" style={{width:"300px",height:"150px"}} src={catImg} alt="" />;
	// }else{

	// 	  preview = this.state.imagelist.map((imagelist_fi, index) =>{
	// 		 catImg = productURL + "/" + imagelist_fi.image_product;
	// 		// console.log("hii",catImg);
   	//       return(
   	//       	   <>
   	//       	  <div className="form-group" key={index+'imagelist'}>
	//    	      <img className="img_class" style={{width:"300px",height:"150px"}} src={catImg} alt="" />
	//    	      </div>
	//    	      <br></br>
	//    	      </>

   	//       	);
    //  });

	// }
	if ((product_image !== null) && (product_image !== '') && (imagelist === '')) {
		// Check if the product_image is in serialized format
		if (product_image.includes('a:') && product_image.includes('s:')) {
			
			const matches = product_image.match(/"([^"]+)"/); 
			
			if (matches && matches[1]) {
				const imageFileName = matches[1];
				const catImg = productURL + "/" + imageFileName;
				console.log("Extracted Image URL (serialized):", catImg);
				
				preview = <img className="img_class" style={{width:"300px", height:"150px"}} src={catImg} alt="" />;
			} else {
				console.log("No valid image found in the product_image string.");
			}
		} else {
			const catImg = productURL + "/" + product_image;
			console.log("Normal Image URL:", catImg);
			
			preview = <img className="img_class" style={{width:"300px", height:"150px"}} src={catImg} alt="" />;
		}
	} else {
		
		preview = this.state.imagelist.map((imagelist_fi, index) => {
			let catImg;
			
			if (imagelist_fi.image_product.includes('a:') && imagelist_fi.image_product.includes('s:')) {
				const matches = imagelist_fi.image_product.match(/"([^"]+)"/); 
				
				if (matches && matches[1]) {
					const imageFileName = matches[1];
					catImg = productURL + "/" + imageFileName;
					console.log("Extracted Image URL from imagelist (serialized):", catImg);
				} else {
					console.log("No valid image found in imagelist_fi.image_product string.");
				}
			} else {
				
				catImg = productURL + "/" + imagelist_fi.image_product;
				console.log("Normal Image URL from imagelist:", catImg);
			}
	
			return (
				<React.Fragment key={index + 'imagelist'}>
					<div className="form-group">
						<img className="img_class" style={{width:"300px", height:"150px"}} src={catImg} alt="" />
					</div>
					<br/>
				</React.Fragment>
			);
		});
	}
	



	
    return (
      <div className="wrapper"> 
 
	  <Header />
    {(lasturrl ==='outstock')?
    <>
     <MenuSidebar currentpage="product-outstock" />
     </>:<>
     <MenuSidebar currentpage="product" /> 
    </>
  }
      <div className="content">	
		<div className="content-wrapper">
		<div className="content-wrapper-before"></div>
		<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="back-btn-lux">
                  <a onClick={() => navigate(-1)}>Back</a>    
                </div> 
			<div className="title">
				<h4>Product Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data">
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Product Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="product_name" onChange={this.handleInputChange} className="form-control" value={this.state.product_name} />
					<div className="errorproduct_name"></div>
				</div>

				<div className="form-group">					
					<label>Category: </label>
						<Select 
                         options={categorys}  
                         value={selectedCategory?selectedCategory:{ value: '0', label: 'Select Category' }}
                         onChange={this.handleChangeCategory}
                         placeholder="Select Category" />
					<div className="errorcat_name"></div>
				</div>	
	        	{/* <div className="form-group">					
					<label>Description:</label>
						<textarea type="text" name="description" onChange={this.handleInputChange} className="form-control" value={this.state.description}  />
					<div className="errordescription"></div>
				</div> */}
				<div className="form-group">					
						<label>Description:</label>
						<CKEditor
										editor={ ClassicEditor }
										data={this.state.description}
										onReady={ editor => {
											
										} }
										onChange={ ( event, editor ) => {
											const data = editor.getData();
											this.setState({description: data});
											console.log( data,"data");
										} }
									/>
					 
						<div className="errorpackage_content"></div>
					</div>	
					<div className="form-group">
						<label>Unlimited Quantity:</label>
							<div className="radio-options">
								<label className='unlimited-quantity'>
									<input type="radio" name="unlimited_quantity" value="yes" onChange={this.handlequntityInputChange} checked={this.state.unlimited_quantity === 'yes'} />
									Yes
								</label>
								<label className='unlimited-quantity'>
									<input  type="radio"   name="unlimited_quantity"  value="no"   onChange={this.handlequntityInputChange} checked={this.state.unlimited_quantity === 'no'}/>
									No
								</label>
							</div>
					</div>

				
						
				{/* <div className="form-group">	
					<label>Quantity<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="product_quantity" onChange={this.handleInputChange} className="form-control" value={this.state.product_quantity} />
					<div className="errorproduct_quantity"></div>
				</div> */}
							{this.state.unlimited_quantity === 'no' && (
							<div className="form-group">
								<label>
										Quantity<span className="required" style={{ color: "red" }}> * </span> :
								</label>
								<input  type="text"  name="product_quantity" onChange={this.handleInputChange} className="form-control" value={this.state.product_quantity}
								/>
								<div className="errorproduct_quantity"></div>
							</div>
							)}

				

				
				{(this.state.selectedCategory.value == 406 || this.state.selectedCategory.value == 407) &&  
						<div className="form-group">

						<label>Gift Discount Type: </label>
						<Select 
						value={selectedgdisctype?selectedgdisctype:{ value: '', label: 'Please Select'}}
						options={discount} 
						onChange={this.handleChangegdiscount}
						isSearchable={false}
						/>
						<label>Discount: </label>
						<input onChange={this.handleInputChange} type="text" name="discount_gprice" value={this.state.discount_gprice} />
						</div> }

				<div className="form-group">	
				 <label>Sale Start Date: </label>
					<DatePicker
						name="startDate"
						selected={this.state.startDate}
						onChange={date => this.setStartDate(date)}
						showTimeSelect
						timeFormat="h:m a"
						timeIntervals={30}
						timeCaption="time"
						dateFormat="M-d-Y H:m"
					/>
			    </div>

				<div className="form-group">
			    <label>Product Image:</label>
			    <div className="choose-car" onClick={this.opengalleryImages.bind(this)}>
					<span>Upload from gallery</span>
					</div>
					<p className="or"><b>OR</b></p>
				<div className="choose-file">
                {this.state.isHidden && <div className="image_success"></div>}
                   <span className="profile_btn"><input type="file" name="file[]" multiple onChange={this.onChangeHandler} /></span>
                </div>
                <div className="errorimage"></div>
                {preview}
                </div>


				
				
				<div className="form-group">
					<label>Product Subscription: </label>
            
						<div className="form-group">	
							<label>Price $<span class="required" style={{ color: "red" }} > * </span> : </label>
							<input type="text" name="price" onChange={this.handleInputChange} className="form-control" value={this.state.price} />
							<div className="errorprice"></div>
						</div>

						<div className="form-group">
								<input onChange={this.handleInputChange} type="checkbox"  name="recurring" checked={this.state.recurring_checked}/>
							<span>Recurring</span>
						</div>
            
		    	</div> 

			<div className="product-subscription">
          {(this.state.recurring_checked === true)? 
            	 <>
            <div className="form-group">
					<label>Subscription Plan & Price<span class="required" style={{ color: "red" }} > * </span> : </label>
				   	
				 </div>

			<div className="product-subscription-form-row">
			
                {subscrlist}
                {(this.state.sublist_final == '')? 
            	 <>
                 <div className="form-group">
					                    
                    {this.state.subscriptionlist.map((subscriptionlist,index) => 
                    	 <>
                    	  <span>{subscriptionlist.subscription_name+' $'} </span>
                    	<input onChange={this.handleInputChange} type="text" data-ref={index} id={subscriptionlist.subscription_id} name="subscription_plan[]" />

	                
	                </>)}
					<div className="errorsubscription_plan"></div>
				 </div>
				  
				  </>
              
            : '' } </div> 

				  </>
              
            : '' } 
				</div>

                {this.state.enableuploadgalley ==  true && <div className="cargallery-popup active">
					<span className="close-gallery" onClick={this.galleryClose.bind(this)}>X</span>
					<div className="cargallery-popup-inner">
						<Scrollbars className="custom-scroll-wrap">
						 <div className="car-listing">
								{this.getGalleryimages()}
						</div>
						</Scrollbars>
					</div>						

				<div className="btn-group">	
					  <span className="btn" onClick={this.galleryClose.bind(this)} disabled={(this.state.Loading ===true)?true:false}> 
					  {this.state.Loading ===true &&  <span className="load-data"></span>}OK</span>
				</div>
				
				</div>}
			
			</div>
                  
			<div className="form-right">

			<div className="form-group">
					<label>Stores<span className="required" style={{ color: "red" }} > * </span> : </label>
					<select name="store_name" onChange={this.handleChangeStore} value={this.state.store_id} >
						{this.state.storenamelist.map((store) => (
						<option
							key={store.value}
							value={store.value}
						>
							{store.label}
						</option>
						))}
					</select>
					<div className="errorstore_name"></div>			
	           	</div> 
			{/*<div className="form-group">
					<label>Short Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="short_name" onChange={this.handleInputChange} className="form-control" value={this.state.short_name} />
					<div className="errorshort_name"></div>
				</div>*/}


				{/* <div className="form-group">					
					<label>Short Description:</label>
						<textarea type="text" name="short_description" onChange={this.handleInputChange} className="form-control" value={this.state.short_description}  />
					<div className="errorshort_description"></div>
				</div> */}
				<div className="form-group">					
						<label>Short Description:</label>
						<CKEditor
										editor={ ClassicEditor }
										data={this.state.short_description}
										onReady={ editor => {
											// You can store the "editor" and use when it is needed.
											console.log( 'Editor is ready to use!', editor );
										} }
										onChange={ ( event, editor ) => {
											const data = editor.getData();
											this.setState({short_description: data});
											console.log( data,"data");
										} }
									/>
					 
						<div className="errorpackage_content"></div>
					</div>
				<div className="form-group">	
					<label>Color<span class="required" style={{ color: "red" }} > * </span> : </label>

					<Select 
                         options={colors}  
                         value={selectedColor?selectedColor:{ value: '0', label: 'Select Color' }}
                         onChange={this.handleChangeColor}
                         placeholder="Select Color" 
						 selected=""
						 isSearchable={false}
					/>

				   {/*  <input type="text" name="product_color" onChange={this.handleInputChange} className="form-control" value={this.state.product_color} /> */}
					<div className="errorproduct_color"></div>
				</div>

				<div className="form-group">	
					<label>Priority</label>
				    <input type="text" name="product_priority" onChange={this.handleInputChange} className="form-control" value={this.state.product_priority} />
					<div className="errorproduct_priority"></div>
				</div>

				{/* <div className="form-group">	
					<label>Dimension<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="product_dimension" onChange={this.handleInputChange} className="form-control" value={this.state.product_dimension} />
					<div className="errorproduct_dimension"></div>
				</div> */}

				<div className="form-group">					
					<label>Product image dimension<span class="required" style={{ color: "red" }} > * </span> : </label>
						<Select 
							 options={lang.common.image_size_option}  
							value={selectedDimension?selectedDimension:{ value: '0', label: 'Select Dimension' }}
							onChange={this.handleChangeDimension}
							placeholder="Select Dimenesion" />
					<div className="errorproduct_dimension"></div>
				</div>	

				<div className="form-group">	
				    <label>Sale End Date: </label>
					<DatePicker
						name="endDate"
						selected={this.state.endDate}
						onChange={date => this.setEndDate(date)}
						showTimeSelect
						timeFormat="h:m a"
						timeIntervals={30}
						timeCaption="time"
						dateFormat="M-d-Y H:m"
					/>
			     </div>

             
	            <div className="form-group">
					<label>Product Type<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <select  onChange={this.handleClickSelecetgoods}  >                    
                    <option key='digital' data-key='digital' value={this.state.goods_type} selected={this.state.goods_type=='digital'} >Digital</option>
                    <option key='nondigital' data-key='nondigital' value={this.state.goods_type} selected={this.state.goods_type=='nondigital'} >Non Digital</option>
                    </select>
					<div className="errorcategory_name"></div>
				 </div> 

			

				<div className="form-group">

					<label>Discount Type: </label>
					<Select 
					value={selecteddisctype?selecteddisctype:{ value: '', label: 'Please Select'}}
					options={discount} 
					onChange={this.handleChangediscount}
					isSearchable={false}
					/>
					<label>Discount: </label>
					<input onChange={this.handleInputChange} type="text" name="discount_price" value={this.state.discount_price} />
				</div> 

				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
							isSearchable={false}
                            />
	           </div>


	            <div className="form-group">
				    <input onChange={this.handleInputChange} type="checkbox" name="free_shipping" checked={this.state.free_shipping_checked}/>
	                <span>Free Shipping</span>
	                 
                </div> 

			    <div className='form-group'>
				     <h4>Image Embed URL </h4>
				    <div className="domain-url">
					    <Addembedinput myCallback={this.myCallback} imagename={this.state.imageSets}/>
					</div>
		     	 </div>

			</div>	
			</div>	
			<div className='add-variants-details'>
						<div className="title title-center">
							<h4>Variants</h4> 
						</div>
						<button type="button"  class="title-end"  onClick={this.addRow} >
							Add  Variants
						</button>
						</div> 
									{/* <div className="form-row"> */}
									{this.state.rows.map((row, index) => (
											<VariantRow
												key={index}
												row={row}
												index={index}
											
												catenamelist={this.state.catenamelist}
												catesizelist={this.state.catesizevarientlist}
												catecolorlist={this.state.catecolorvarientlist}
												cateweightlist={this.state.cateweightvarientlist}
												catevarientlist={this.state.catevarientlist}
												onInputChange={this.handleInputvarieantChange}
												onSelectChange={this.handleSelectChange}
												onDelete={this.deleteRow}
												onImageChange={this.handleImageVariantChange}
											/>
											))}
											{/* <button type="button" onClick={this.addRow}>
												Add Row
											</button> */}
											{/* <button type="button" onClick={() => this.deleteRow}>
												Delete Row
											</button> */}
								   
								    

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default withRouter(Edit);