import React, { Component } from 'react';


import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import { apiUrl, carImageUrl, currency, dummycarUrl,categoryURL} from'../Config/Config';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth, scrollToTopValidate } from "../Helpers/SettingHelper";
import { useParams, useNavigate } from 'react-router-dom';
import CKEditor from 'ckeditor4-react';
import axios from 'axios';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };


class Add extends Component {
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state = {
			subscription_name:'',
			subscription_days:'',
			selectedOption:{ value: 'active', label: 'Active'},
			status:'',	
			Loading:false,
		};
		
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.handleChange = this.handleChange.bind(this);
    }
	

   handleInputChange(event) {

      const {name, value} = event.target;    
      this.setState({
          [name]: value
        });
    }

    
	componentDidMount() {
		document.title = PageTitle('Subscription Add');		
    }

     handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };



	 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});


				const formPayload = this.state;
				var qs = require('qs');
				const config = {
					headers: {
						'content-type': 'multipart/form-data'
					}
				}
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
			
				var admin_id = localStorage.getItem("admin_id");
				var postObject = {
					admin_id     : admin_id,
					subscription_name   : formPayload.subscription_name,
					subscription_days: formPayload.subscription_days,
					status       :  status,
				};
				

				axios.post(apiUrl+"subscription/add",postObject,config).then(res => {
					if(res.data.status === "success"){
							$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
									setTimeout(
									function() {
										this.props.navigate('/subscriptions');
										// $('.success_message').html(this.props.history.push('/color'));
									}
									.bind(this),
									3000
									);
						}else{
							$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
							
							setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							3000
							);
						}
					});
				
			}
	}

	validateForm() {

		const {subscription_name,subscription_days} = this.state;

		let errors = 0;
	
		if (!subscription_name) {
			errors++;
			$('.errorsubscription_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(subscription_name){
			$('.errorsubscription_name').html('');
		}
		
		if (!subscription_days) {
			errors++;
			$('.errorsubscription_days').html('<span class="errorspan">Please fill the field</span>');
		}else if(subscription_days){
			$('.errorsubscription_days').html('');
		}



		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

    componentWillReceiveProps(Props){

    	 	if(Props.subscriptionadd !== this.props.subscriptionadd){
    		if(Object.keys(Props.subscriptionadd).length > 0){
				this.setState({Loading:false});
					if(Props.subscriptionadd[0].status === "success"){
		            	$('.success_message').html('<div class="status_sucess"><h3>'+Props.subscriptionadd[0].message+'</h3></div>');
						setTimeout(
						function() {
							this.setState({subscription_name:'',subscription_days:''})
							$('.success_message').html(this.props.history.push('/subscriptions'));
							
						}
						.bind(this),
						3000
						);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+Props.subscriptionadd[0].message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}

    		}
    	}
      }

  render() {

  	const {selectedOption} = this.state;
	  const navigate = this.props.navigate;
    return (
      <div className="wrapper"> 
	  <Header />
      <MenuSidebar currentpage="subscriptions" />  
	 <div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
				<div className="back-btn-lux">
					<a onClick={() => navigate(-1)}>Back</a>    
				</div> 
			<div className="title">
				<h4>Add subscription</h4>
			</div>
		<form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row ">
			<div className="form-left ">
		    	<div className="form-group">
					<label>Subscription Name:</label>
				    <input type="text" name="subscription_name" onChange={this.handleInputChange} className="form-control" value={this.state.subscription_name} autoComplete="off" />
					<div className="errorsubscription_name"></div>
				</div>

				{/* <div className="form-group">
					<label>Subscription Period:</label>
				    <input type="text" name="subscription_days" onChange={this.handleInputChange} className="form-control" value={this.state.subscription_days} autoComplete="off" />
					<div className="errorsubscription_days"></div>
				</div> */}

				 <div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
	           </div> 
				
				
			</div>				
			<div className="form-left ">
		    	{/* <div className="form-group">
					<label>Subscription Name:</label>
				    <input type="text" name="subscription_name" onChange={this.handleInputChange} className="form-control" value={this.state.subscription_name} autoComplete="off" />
					<div className="errorsubscription_name"></div>
				</div> */}

				<div className="form-group">
					<label>Subscription Period:</label>
				    <input type="text" name="subscription_days" onChange={this.handleInputChange} className="form-control" value={this.state.subscription_days} autoComplete="off" />
					<div className="errorsubscription_days"></div>
				</div>

				 {/* <div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
	           </div>  */}
				
				
			</div>
			</div>		

			<div className="btn-group export">	
				   <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}





export default withRouter(Add);