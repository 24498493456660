import React, { Component } from 'react';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth,scrollToTop } from "../Helpers/SettingHelper";

import { apiUrl, carImageUrl, currency, dummycarUrl,categoryURL} from'../Config/Config';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };


class Edit extends Component {
	fileObj = [];
    fileArray = [];
    imageArray = [];
	constructor(props)
	{
		CheckAuth();
		super(props);	
		const categoryId = (typeof this.props.params.ID === 'undefined') ? '' : this.props.params.ID;
	   
		this.state = {
           // domain_id:'',
            categoryId: categoryId,
			domain_id:'',
			cat_name:'',
			short_name:'',			
			cat_priority:'',
			cat_trending:0,
			description:'',
			short_description:'',
			cat_image:'',
			cat_image_white:'',
			fileName: [],
			thumbDefault: '',
			thumbtypeimg: [],	
			image: [],
			image1: [],
			status:'',
			Loading:false,
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			cat_trending_checked:false,
			selectedForm:'',
			formvalue:'',
			formlist:[],
			products:1,
			forms:0,
			formbuildernamelist:[],
			form_select:'',
			cat_image_yellow:'',
			cat_image_preview2:'',
			image2:[],
			cat_image_name2:'',
			form_id:0,
			category_bg:'white'
		};

		axios.get(apiUrl+"category/listdetail?id="+categoryId).then(res => {

			if(res.data.status === 'success'){  
						const formdata = res.data.categorylist;

						if(formdata.status === 'A'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

						}

						if(formdata.cat_trending === '1'){
						this.setState({cat_trending_checked: true});
						}else{
						this.setState({cat_trending_checked: false});
						}


						if( parseInt (formdata.form_builder_id) !==0 ){

							this.setState({forms: 1,products:0});
							this.setState({form_select: 'forms'});
						}else{
							this.setState({forms: 0,products:1});
							this.setState({form_select: 'products'});
						}

					


						  if(res.data.selectedform && res.data.selectedform[0]!==null && res.data.selectedform.length > 0){
							if(res.data.selectedform.value!== '' && res.data.selectedform.value!== null){
								this.setState({ selectedForm : res.data.selectedform})
								localStorage.setItem("original_selected_form",res.data.selectedform[0].value)
								}
						}
						if(formdata.length > 0){
							if(formdata.formlist!== '' && formdata.formlist!== null){
								this.setState({ formlist : formdata.formlist})
							}
						}



						

						this.setState({cat_name:formdata.cat_name});
						this.setState({short_name:formdata.short_name});						
						this.setState({description: formdata.cat_description});						
						this.setState({short_description: formdata.short_description});
						this.setState({category_bg: formdata.cat_bg});
						this.setState({cat_priority: formdata.cat_priority});
                        this.setState({cat_image: formdata.cat_image});
                        this.setState({cat_image_white: formdata.cat_image_white});
						this.setState({cat_image_yellow: formdata.cat_image_yellow});
                        this.setState({categoryId: formdata.category_id});
				} else {

				}
		}); 

		// this.props.getCategoryDetailList(categoryId);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onChangeHandler1 = this.onChangeHandler1.bind(this);
	    this.handleChange = this.handleChange.bind(this);

    }

	

	onChangeHandler=event=>{
    let reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {		
		
      this.setState({
        cat_image_name: file.name,
        cat_image_preview: reader.result,
        image: file
      });
	 
		
    };

    reader.readAsDataURL(file);
  }

  onChangeHandler1=event=>{
    let reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {		
		
      this.setState({
        cat_image_name1: file.name,
        cat_image_preview1: reader.result,
        image1: file
      });
	 
		
    };

    reader.readAsDataURL(file);
  }

  onChangeHandler2=event=>{
    let reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {		
		
      this.setState({
        cat_image_name2: file.name,
        cat_image_preview2: reader.result,
        image2: file
      });
	 
		
    };

    reader.readAsDataURL(file);
  }
  onChangeHandler2=event=>{
    let reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {		
		
      this.setState({
        cat_image_name2: file.name,
        cat_image_preview2: reader.result,
        image2: file
      });
	 
		
    };

    reader.readAsDataURL(file);
  }


  onThumbChanged = (e) => {	
    this.setState({
      thumbDefault: e.currentTarget.value
      });
	  
  }
  
	

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {


      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
		console.log(name,value)

    // console.log(!$(this).is(':checked'));

      if(name === 'cat_trending'){
      	this.setState({cat_trending_checked: !this.state.cat_trending_checked});
      }

    
		
    }


	componentDidMount() {
		document.title = PageTitle('Category Edit');

		 var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl+'category/getformbuildernamelist?admin_id='+admin_id)
			
			.then(res => {
				if(res.data.status == 'success'){
				
					this.setState({
						formbuildernamelist: [{value: '', label: 'Select Form'}].concat(res.data.formbuildernamelist)
					})

					
				
				}else{
				//console.log("test")
				}
			});
    }

	
        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}

				var cat_trending = ''
				if(this.state.cat_trending_checked === true){
					 cat_trending = 1;
				}else{
					 cat_trending = 0;
				}

			
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
                    domain_id : formPayload.domain_id,
					cat_name: formPayload.cat_name,
					short_name:formPayload.short_name,
                    cat_priority: formPayload.cat_priority,
                    cat_trending: cat_trending,
					cat_description: formPayload.description,
					short_description:formPayload.short_description,	
					category_bg:formPayload.category_bg,					
					cat_image:formPayload.image,
					cat_image_white:formPayload.image1,
					cat_image_yellow:formPayload.image2,
					status:  status,
					id: formPayload.categoryId,
					form_id:formPayload.form_id,
				};
				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}		       
				axios.post(apiUrl+"category/edit",formData,config).then(res => {

					if(res.data.status === "success"){
	
							scrollToTop();
							$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
	
									setTimeout(
									function() {
										this.props.navigate('/category');
									}
									.bind(this),
									3000
									);
						}else{
							scrollToTop();
							$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
							
							setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							3000
							);
						}
				});
			    // this.props.getEditCategory(formData,config);
			}
	}

	validateForm() {
		//const {admin_company_name,admin_fname} = this.state;
		const {cat_name,short_name,cat_priority,description,short_description,image,image1,image2} = this.state;
		let errors = {};
      	let formIsValid = true;
		
		if (!cat_name) {
			formIsValid = false;
			$('.errorcat_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(cat_name){
			$('.errorcat_name').html('');
		}

	
     

		{/*if (!short_name) {
			$('.errorshort_name').html('<span class="errorspan">Please select the field</span>');
		}else if(short_name){
			$('.errorshort_name').html('');
		}*/}
		

		if (!cat_priority) {
			formIsValid = false;
			$('.errorcat_priority').html('<span class="errorspan">Please fill the field</span>');
		}else if(cat_priority){
			$('.errorcat_priority').html('');
		}

		if (!description) {
			formIsValid = false;
			$('.errordescription').html('<span class="errorspan">Please fill the field</span>');
		}else if(description){
			$('.errordescription').html('');
		}

		if (!short_description) {
			formIsValid = false;
			$('.errorshort_description').html('<span class="errorspan">Please fill the field</span>');
		}else if(short_description){
			$('.errorshort_description').html('');
		}

		/*if (image=='') {
			formIsValid = false;
			$('.errorimage').html('<span class="errorspan">Please fill the field</span>');
		}else if(image){
			$('.errorimage').html('');
		}

		if (image1=='') {
			formIsValid = false;
			$('.errorimage1').html('<span class="errorspan">Please fill the field</span>');
		}else if(image1){
			$('.errorimage1').html('');
		}*/

		this.setState({
			errors: errors
		});
		return formIsValid;
    }

    componentWillReceiveProps(Props){


   	if(Props.categorydetails !== this.props.categorydetails){
   		
    		if(Object.keys(Props.categorydetails).length > 0){
				this.setState({Loading:false});

					if(Props.categorydetails[0].status === "success"){

						const formdata = Props.categorydetails[0].categorylist;

						if(formdata.status === 'A'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

						}

						if(formdata.cat_trending === '1'){
						this.setState({cat_trending_checked: true});
						}else{
						this.setState({cat_trending_checked: false});
						}


						if( parseInt (formdata.form_builder_id) !==0 ){

							this.setState({forms: 1,products:0});
							this.setState({form_select: 'forms'});
						}else{
							this.setState({forms: 0,products:1});
							this.setState({form_select: 'products'});
						}

					


						  if(Props.categorydetails[0].selectedform && Props.categorydetails[0].selectedform[0]!==null && Props.categorydetails[0].selectedform.length > 0){
							if(Props.categorydetails[0].selectedform[0].value!== '' && Props.categorydetails[0].selectedform[0].value!== null){
								this.setState({ selectedForm : Props.categorydetails[0].selectedform[0]})
								localStorage.setItem("original_selected_form",Props.categorydetails[0].selectedform[0].value)
								}
						}
						if(Props.categorydetails.length > 0){
							if(Props.categorydetails[0].formlist!== '' && Props.categorydetails[0].formlist!== null){
								this.setState({ formlist : Props.categorydetails[0].formlist})
							}
						}



						

						this.setState({cat_name:formdata.cat_name});
						this.setState({short_name:formdata.short_name});						
						this.setState({description: formdata.cat_description});						
						this.setState({short_description: formdata.short_description});
						this.setState({category_bg: formdata.cat_bg});
						this.setState({cat_priority: formdata.cat_priority});
                        this.setState({cat_image: formdata.cat_image});
                        this.setState({cat_image_white: formdata.cat_image_white});
						this.setState({cat_image_yellow: formdata.cat_image_yellow});
                        this.setState({categoryId: formdata.category_id});


                        
					}
    		}
    	}

    	if(Props.categoryedit !== this.props.categoryedit){
    		if(Object.keys(Props.categoryedit).length > 0){
				if(Props.categoryedit[0].status === "success"){
    					this.setState({ Loading: false });

    			const formpayload = Props.categoryedit[0].categorylist;

				if(formpayload.status === 'A'){
					this.setState({selectedOption:{value: 'active', label: 'Active'}});
					}else{
					this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
	
					}

					if(formpayload.cat_trending === '1'){
						this.setState({cat_trending_checked: true});
						}else{
						this.setState({cat_trending_checked: false});
						}


						if( parseInt (formpayload.form_builder_id) !==0 ){
							this.setState({forms: 1,products:0});
							this.setState({form_select: 'forms'});
						}else{	
							this.setState({forms: 0,products:1});
							this.setState({form_select: 'products'});
						}

						


				        this.setState({cat_name:formpayload.cat_name});
						this.setState({short_name:formpayload.short_name});						
						this.setState({description: formpayload.cat_description});						
						this.setState({short_description: formpayload.short_description});
						this.setState({category_bg: formpayload.cat_bg});
						this.setState({cat_priority: formpayload.cat_priority});
                        this.setState({cat_image: formpayload.cat_image});
                        this.setState({cat_image_white: formpayload.cat_image_white});
                        this.setState({categoryId: formpayload.category_id});
						this.setState({cat_image_yellow: formpayload.cat_image_yellow});
				
                    	$('.success_message').html('<div class="status_sucess"><h3>'+ Props.categoryedit[0].message+'</h3></div>');
               			scrollToTop();
    					setTimeout(
						function() {
							$('.success_message').html(this.props.history.push('/category'));
						}
						.bind(this),
						3000
						);
						
    		}else{
					this.setState({ Loading: false });
					$('.success_message').html('<div class="status_sucess"><h3>'+ Props.categoryedit[0].message+'</h3></div>');
					scrollToTop();
    					setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
			}
			}
    	}
		
    }


    handleChangeForm = selectedForm => {
	
			this.setState({ selectedForm});
		    this.setState({ form_id : selectedForm.value });

	 }
	
    handleClickSelecetForm = (event) => {
		const selectedIndex = event.target.options.selectedIndex;
		var keys = event.target.options[selectedIndex].getAttribute('data-key');

		 if (keys === 'products') {
               this.setState({products: 1,forms : 0,form_id:null})
               }else if (keys === 'forms'){
               	   this.setState({forms: 1,products: 0})
               }

	}

  render() {
  	const formArray = this.state.formlist.map ((formlist, index) => ({
			id: index,
			name: formlist,
			}));
			// Dynamically create select list
			let forms = [];
			formArray.map(item =>
			forms.push({ label: item.name.label, value: item.name.value }),
			);

	let catImg = '';
	let preview = '';
	let catImg2 = '';
	let preview2 = '';
	let preview3= '';
	let catImg3= '';

  	  const {selectedOption,cat_image,cat_image_white,selectedForm,formnamelist,cat_image_yellow} = this.state;
  	  if (cat_image!== null && cat_image!== '') {
	    catImg = categoryURL + "/" + cat_image;
		preview = <img className="img_class" src={catImg} alt="" />;
	}

	 if (cat_image_white!== null && cat_image_white!== '') {
	    catImg2 = categoryURL + "/" + cat_image_white;
		preview2 = <img className="img_class" src={catImg2} alt="" />;
	}
	console.log(preview2,'preview2')
	
	if(cat_image_yellow!== null && cat_image_yellow!==''){
		catImg3 = categoryURL + "/" + cat_image_yellow;
		preview3 = <img className="img_class" src={catImg3} alt="" />;
	}
	const navigate = this.props.navigate;
    return (
      <div className="wrapper"> 
 
	  <Header />
	  <MenuSidebar currentpage="Category" />  
      <div className="content">	
		<div className="content-wrapper">
		<div className="content-wrapper-before"></div>
		<div className="form-wrapper leaa-admin-head card">
				<div className="back-btn-lux">
					<a onClick={() => navigate(-1)}>Back</a>    
				</div> 
			<div className="success_message"></div>
			<div className="title">
				<h4>Category Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data">
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Category Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="cat_name" onChange={this.handleInputChange} className="form-control" value={this.state.cat_name} />
					<div className="errorcat_name"></div>
				</div>

				
	        <div className="form-group">					
					<label>Description:</label>
						<textarea type="text" name="description" onChange={this.handleInputChange} className="form-control" value={this.state.description}  />
					<div className="errordescription"></div>
				</div>

				<div className="form-group">
					
					<input onChange={this.handleInputChange} type="checkbox"  name="cat_trending" checked={this.state.cat_trending_checked}/>
				    <label> Trending Category  </label>
				</div>
				<div className="form-group">
			    <label>Category Image (Black Theme):</label>
				<div className="choose-file">
                {this.state.isHidden && <div className="image_success"></div>}
                   <span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
                </div>
                 {preview}
                <div className="errorimage"></div>
                </div>

                <div className="form-group">
			    <label>Category Image (White Theme):</label>
				<div className="choose-file">
                {this.state.isHidden1 && <div className="image_success1"></div>}
                   <span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler1} /></span>
                </div>
                 {preview2}
                <div className="errorimage1"></div>
                </div>

				<div className="form-group">
			    <label>Category Image (Yellow Theme):</label>
				<div className="choose-file">
                {this.state.isHidden1 && <div className="image_success2"></div>}
                   <span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler2} /></span>
                </div>
                 {preview3}
                <div className="errorimage2"></div>
                </div>
			
			</div>
                  
			<div className="form-right">
			{/*<div className="form-group">
					<label>Short Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="short_name" onChange={this.handleInputChange} className="form-control" value={this.state.short_name} />
					<div className="errorshort_name"></div>
				</div>*/}

				<div className="form-group">					
					<label>Short Description:</label>
						<textarea type="text" name="short_description" onChange={this.handleInputChange} className="form-control" value={this.state.short_description}  />
					<div className="errorshort_description"></div>
				</div>

				<div className="form-group">	
					<label>Priority<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="cat_priority" onChange={this.handleInputChange} className="form-control" value={this.state.cat_priority} />
					<div className="errorcat_priority"></div>
				</div>

				<div className="form-group">
					<label>Show Type<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <select  onChange={this.handleClickSelecetForm} > 
                    <option key='products' data-key='products' selected={this.state.form_select=='products'} value='products' >Products</option>
                    <option  key='forms' data-key='forms' selected={this.state.form_select=='forms'}  value='forms' >Form Builder</option>
                    </select>
					<div className="errorcategory_name"></div>
				 </div>


				 <div className="form-group">
					 <label>Category Background: </label>
					    <input onChange={this.handleInputChange} type="radio" name="category_bg" checked={this.state.category_bg=='white'?'chekced':''} value="white"/>
	                <span>White</span>
	                 <input onChange={this.handleInputChange} type="radio"  name="category_bg" checked={this.state.category_bg=='black'?'chekced':''} value="black"/>
	                <span>Black</span>
            	</div> 

                { ((this.state.products == 1))? 
				  <>
				<div className="form-group">
					<label>Category Plan<span class="required" style={{ color: "red" }} > * </span> : </label>
					<div className="errorcat_plan"></div>
				</div>
                 </>
				: '' }

				 { ((this.state.forms == 1))? 
				  <>
	              <div className="form-group">					
					<label>Form Builder (Forms): </label>
						<Select 
                         options={forms}  
                         value={selectedForm?selectedForm:{ value: '0', label: 'Select Form' }}
                         onChange={this.handleChangeForm}
                         placeholder="Select Form" />
					<div className="errorcat_name"></div>
				</div>	
				</>
            : '' }

               <div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
	           </div> 

			</div>	
			</div>		



			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}



export default withRouter(Edit);