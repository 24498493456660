import React, { Component } from 'react';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { useParams, useNavigate } from 'react-router-dom';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import { apiUrl} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };

class Edit extends Component {

	constructor(props)
	{
		CheckAuth();
		super(props);	
		const cateId = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
	   
		this.state = {
            cateId: cateId,
			title:'',
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			selectedVariantOption:'',
			catenamelist:[],	
			displayColorPicker: false,
			color: {
			r: '241',
			g: '112',
			b: '19',
			a: '1',
			}	
		};
		// this.props.getCategoryDetailList(cateId);
		var qs = require('qs');
		var catid = this.state.cateId;
		 var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl + 'variants/getvariantnamelist?admin_id=' + admin_id)
    .then(variantNameListResponse => {
        if (variantNameListResponse.data.status === 'success') {
            const catenamelist = variantNameListResponse.data.catenamelist;
            this.setState({ catenamelist });

            // Return the second API call: listdetail
            return axios.get(apiUrl + "variants/listdetail?id=" + catid);
        } else {
            throw new Error('Failed to fetch variant name list.');
        }
    })
    .then(listDetailResponse => {
        const { var_catelist } = listDetailResponse.data;

        // Set the title
        this.setState({ title: var_catelist.var_name,hexColor:var_catelist.var_color });

        // Match the option from catenamelist
        const matchedOption = this.state.catenamelist.find(
            item => item.value === var_catelist.var_cate_id
        );
        console.log(matchedOption, 'this is for matched variant cate');

        // Prefill the selectedVariantOption if a match is found
        if (matchedOption) {
            this.setState({ 
                selectedVariantOption: matchedOption, 
                selectedvariantvalue: matchedOption.value 
            });
        }

        // Set the selectedOption based on status
        this.setState({
            selectedOption: var_catelist.status === 'A'
                ? { value: 'active', label: 'Active' }
                : { value: 'inactive', label: 'Inactive' }
        });
    })
    .catch(error => {
        console.error('Error fetching data:', error);
    });
		// var admin_id = localStorage.getItem('admin_id');
		// axios.get(apiUrl+'variants/getvariantnamelist?admin_id='+admin_id)
		// 	.then(res => {
		// 		if(res.data.status == 'success'){
					
		// 			this.setState({
		// 				catenamelist: [{value: '', label: 'Select Varient'}].concat(res.data.catenamelist)
		// 			});
				
		// 		}else{
				
		// 		}
		// 	});
		// axios.get(apiUrl+"variants/listdetail?id="+catid).then(res => {
		// 	this.setState({title:res.data.var_catelist.var_name,});
		// 	const matchedOption = this.state.catenamelist.find(
		// 		item => item.value === res.data.var_catelist.var_cate_id
		// 	);
		// 	console.log(matchedOption,'this is for matched variant cate')
		// 	// Prefill the selectedVariantOption if a match is found
		// 	if (matchedOption) {
		// 		this.setState({ selectedVariantOption: matchedOption,selectedvariantvalue:matchedOption.value });
		// 	}
		// 	if(res.data.var_catelist.status === 'A'){
		// 		this.setState({selectedOption:{value: 'active', label: 'Active'}});
		// 	}else{
		// 	this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
		// 	}
		// }); 		
		
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);

    }

	handleChange = (r,selOption) => {
       
		if(r == 'status'){
			this.setState({selectedOption:selOption})
			this.setState({  selectedvalue : selOption.value});
		}else if(r =='variant'){
			this.setState({selectedVariantOption:selOption})
			this.setState({  selectedvariantvalue : selOption.value});
		}
 	};
	 handleClick = () => {
		this.setState({displayColorPicker: !this.state.displayColorPicker })
		};
	  handleClose = () => {
		this.setState({ displayColorPicker: false })
		};
   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
     		
    }


	componentDidMount() {
		
		document.title = PageTitle('Advertisement Category Edit');
    }

	
        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}

				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					name: formPayload.title,
					status:  status,
					id: formPayload.cateId,
					varcolor : formPayload.hexColor,	
					cate_id : formPayload.selectedvariantvalue,	
					
					
				};

				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}		       
				axios.post(apiUrl+"Variants/edit",formData ).then(res => {
					if(res.data.status === "success"){	
						this.setState({Loading:false});
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
								setTimeout(
								function() {
									$('.success_message').html('');
									this.props.navigate('/variants');
								}
								.bind(this),
								3000
								);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
						this.setState({Loading:false});
					}
				});
			}
	}

	validateForm() {
		const {title} = this.state;
	
		let errors = {};
		  let formIsValid = true;
	
		  if(!title){
			formIsValid = false;
			$('.errortitle').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errortitle').html('');
		}
			
		this.setState({
			errors: errors
		});
		return formIsValid;
	}
	
handleChangecolor = (color) => {
	console.log(color,'this is color variable')
	this.setState({hexColor : color.hex})
	this.setState({ color: color.rgb })
	};
	handleClick = () => {
		this.setState({displayColorPicker: !this.state.displayColorPicker })
		};
	handleClose = () => {
		this.setState({ displayColorPicker: false })
		};
  render() {

  	  const {selectedOption ,selectedVariantOption} = this.state;	
		const navigate = this.props.navigate;

		const styles = reactCSS({
			'default': {
			  color: {
				width: '36px',
				height: '14px',
				borderRadius: '2px',
				background: `${ this.state.hexColor }`,
			  },
			  swatch: {
				padding: '5px',
				background: '#fff',
				borderRadius: '1px',
				boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
				display: 'inline-block',
				cursor: 'pointer',
			  },
			  popover: {
				position: 'absolute',
				zIndex: '2',
			  },
			  cover: {
				position: 'fixed',
				top: '0px',
				right: '0px',
				bottom: '0px',
				left: '0px',
			  },
			},
		  });
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="Variants" />  
      <div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
				<div className="content-wrapper">
					<a onClick={() => navigate(-1)}>Back</a>   
				</div>
			<div className="success_message"></div>
			<div className="title">
				<h4>Variant  Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data">
			<div className="form-row">
			<div className="form-left">
			<div className="form-group">					
					<label>Variant Category<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   name="category"
						   value={selectedVariantOption?selectedVariantOption:{ value: '', label: 'Please select a  category'}}
                            options={this.state.catenamelist} 
							isSearchable = {false}
							onChange={this.handleChange.bind(this, 'variant')}
                        />
	           </div> 
			   {this.state.selectedVariantOption.label == 'Color' && (
				<>
				{/* <div className="form-right"> */}
				<div className="form-group">
						<label>Variant Color <span class="required" style={{ color: "red" }} > * </span> : </label>
						<div className='variant-color-form'>
						<div>
						<div style={ styles.swatch } onClick={ this.handleClick }>
							<div style={ styles.color } />
							</div>
							{ this.state.displayColorPicker ? <div style={ styles.popover }>
							<div style={ styles.cover } onClick={ this.handleClose }/>
							<SketchPicker color={ this.state.color } onChange={ this.handleChangecolor } />
							</div> : null }
							</div>
							
							{this.state.hexColor && (
								<input type="text" name="title" readOnly onChange={this.handleInputChange} className="form-control" value={this.state.hexColor} />
								)}
						</div>
					</div>	
				  
	
				{/* </div>	 */}
			   {/* <div className="form-group">					
					<div className="form-group site-bg-clr"> 
						<label>Variant Color : </label>
							<div style={ styles.swatch } onClick={ this.handleClick }>
							<div style={ styles.color } />
							</div>
							{ this.state.displayColorPicker ? <div style={ styles.popover }>
							<div style={ styles.cover } onClick={ this.handleClose }/>
							<SketchPicker color={ this.state.color } onChange={ this.handleChangecolor } />
							</div> : null }
						</div>
				</div> */}
				</>
				 )}
			  
			</div>
                  
			<div className="form-right">
				<div className="form-group">
					<label>Variant<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="title" onChange={this.handleInputChange} className="form-control" value={this.state.title} />
					<div className="errortitle"></div>
				</div>	
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange.bind(this, 'status')}
                            />
	           </div> 	
              

			</div>	
			</div>		



			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>updating</span>:<span>Submit</span>
                                        }
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Edit));