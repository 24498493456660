import React, { Component } from 'react';
import { Link } from "react-router-dom";

import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import Pagination from "react-js-pagination";
import { useParams, useNavigate } from 'react-router-dom';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import { apiUrl, carImageUrl, currency, dummycarUrl,categoryURL} from'../Config/Config';
import axios from 'axios';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };

class List extends Component {
  
  constructor(props)
  {
    super(props); 
    this.state = {
      fontlist:'',
      editable: false,
      priorityid:'',
      priorityvalue:'',
      activePage: 1,
      totalRecord: '',
      search_all:'',
      categorylist:"",
      admin_id: localStorage.getItem("admin_id")
    };

     var admin_id = localStorage.getItem("admin_id");
     var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var loggedas = localStorage.getItem("loggedas");
    var postobject = {
        activePage: 1,
        search_all: '',
        admin_id: admin_id,
        loggedas: loggedas
    };

     
      axios.post(apiUrl+"category/list",qs.stringify(postobject)).then(res => {
        if(res.data.status === 'success'){
         
            this.setState({  categorylist: res.data, totalRecord: res.data.records_count,data_status: res.data.status}, function() {
            
              this.categorylist();
            });
  
        }
      }); 

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

   
   componentDidMount() {
      document.title = PageTitle('Subscription');
      if(localStorage.getItem('admin_id') === null){
      this.props.history.push('/');
      }
      var admin_id = localStorage.getItem("admin_id");
      var qs = require('qs');
     
   }

   handlePageChange(pageNumber) {
     // console.log(`active page is ${pageNumber}`);
      this.setState({activePage: pageNumber});
      var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var postobject = {
        activePage: pageNumber,
        search_all: this.state.search_all,
        admin_id: admin_id
      };
    //this.props.getSubscriptionList(qs.stringify(postobject))
    }
    listloadedData(postobject=''){ 
      var qs = require('qs');
      if(postobject!=''){
        var postobject = postobject
      }else{
       
        var postobject = {
        activePage: 1,
        search_all: '',
        admin_id: localStorage.getItem('admin_id')  
        };
      }
   
      axios.post(apiUrl+"category/list",qs.stringify(postobject)).then(res => {
        if(res.data.status === 'success'){
         
            this.setState({  categorylist: res.data, totalRecord: res.data.records_count,data_status: res.data.status}, function() {
            
              this.categorylist();
            });
  
        }
      });
  }
    handleInputChange(event) {
    const {name, value} = event.target;      
    var postObject = {             
      activePage   : 1,
      search_all : value,
      admin_id: localStorage.getItem('admin_id')           
    };        
    this.setState({
      [name]: value
    },function(){
      this.listloadedData(postObject)
    });
  }
   handleFormSubmit = () => {
        
          this.setState({Loading:true});
          var qs = require('qs');
          const formPayload = this.state;
          var admin_id = localStorage.getItem("admin_id");
          var postObject = {             
              activePage : 1,
              search_all : formPayload.search_all,
              admin_id  : admin_id         
          };
          //console.log(postObject);
          this.props.getSubscriptionList(qs.stringify(postObject));
        
    }

   

deletesubscription(id){
        var qs = require('qs');
        var admin_id = localStorage.getItem("admin_id");
        var postObject = {
          subscription_id  : id,
          admin_id  : admin_id
        };
      this.props.getSubscriptionDelete(qs.stringify(postObject));
}
confirmPopup = (id, status) => {
  var status = status;
  
    if(status == 'Inactive'){
      status = "Active";
    }else if(status == 'Active'){
      status = "Inactive";
    }else if(status == 'Delete'){
      status="Delete";
    } else if(status =='Copy'){
        status="Copy";
    }
  this.setState({selectid: id, selectedStatus: status})	
  $('.confirm-action').addClass('show');		
}
categorylist() {
  let catImg = '';
  let preview = '';
  console.log(this.props)
    var categorylist = this.state.categorylist;
    if (categorylist != "undefined" && categorylist != null) {
      if (categorylist.status == "success") {
        if (Object.keys(categorylist).length > 0) {
          const helperlistDetails = categorylist.categorylist.map(
            (categorylist, Index) => {
              let sno = Index+1;
              

               if(categorylist.status == 'A'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }
              if (categorylist.cat_image!== null && categorylist.cat_image!== '') {
              catImg = categoryURL + "/" + categorylist.cat_image;
            preview = <img className="img_class"  src={catImg} alt="" />;
          }
              var istyle = { width: 200};
              return (
                <tr key={categorylist.category_id}>
      					<td>{sno}</td>
      					<td>{categorylist.cat_name}</td>
                {/*<td>{categorylist.short_name}</td>*/}
                <td>{preview}</td>
                <td>{status}</td>
                <td>{categorylist.cat_priority}</td>                
                <td className="actiongroup" >
                    
                     
                <Link to={{ pathname: '/category-view/'+categorylist.category_id}} className="" title="View">  <i className="ft-eye" aria-hidden="true"></i></Link>
                    <Link to={{ pathname: '/category-edit/'+categorylist.category_id}} className="" title="Edit">  <i className="ft-edit" aria-hidden="true"></i></Link>
                     {(status == 'Active'?<Link onClick={this.confirmPopup.bind(this, categorylist.category_id, status)} className="" title="Status"><i className="ft-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.confirmPopup.bind(this, categorylist.category_id, status)} className="" title="Status"><i className="ft-lock" aria-hidden="true"></i></Link>)} 

                      <Link onClick={this.confirmPopup.bind(this,categorylist.category_id,'Copy')}  className="" title="Copy category">  <i className="fa fa-copy" aria-hidden="true"></i></Link>

                      <Link onClick={this.confirmPopup.bind(this,categorylist.category_id,'Delete')} className="" title="Delete"><i className="ft-trash-2" aria-hidden="true"></i></Link>
                     
                    
                    
                  </td>
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="11" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }else {
      return (
        <tr>
          <td colspan="11" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
  deletetab(){
    var qs = require('qs');
    var postObject = {
      admin_id : localStorage.getItem("admin_id"),
      id  :this.state.selectid,
    };
    
    axios.post(apiUrl+"category/delete",qs.stringify(postObject)).then(res => {
    if(res.data.status === 'success'){  
      $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message +'</h3></div>');
        setTimeout(
          function() {
            $('.success_message').html('');
             
          }
          .bind(this),
          3000
          );     
          this.listloadedData();
    }
    }); 
    }
    activatecategory(id, status){
      let stat = ''
        if(status == 'Inactive'){
          stat = "A";
        }else{
          stat = "I";
        }
            var qs = require('qs');
            var postObject = {
              admin_id : localStorage.getItem("admin_id"),
              id  :id,
              status: stat
            };
          this.props.getActivateCategory(qs.stringify(postObject));
    }
    copycategory(id){
      var admin_id = localStorage.getItem('admin_id');
      axios.get(apiUrl+'category/setcategorycopy?admin_id='+admin_id+'&catid='+this.state.selectid)
        
        .then(res => {
          if(res.data.status == 'success'){
            var qs = require('qs');
            var admin_id = localStorage.getItem("admin_id");
            var loggedas = localStorage.getItem("loggedas");


            var postobject = {
            activePage: 1,
            search_all: '',
            admin_id: admin_id,
            loggedas: loggedas
            };
            this.listloadedData(postobject)
            // this.props.getCategoryList(qs.stringify(postobject));
          }else{
          //console.log("test")
          }
        });
    }
    
  activatecategory(){
    let stat = ''
    if(this.state.selectedStatus ==='Inactive'){
      stat = "I";
    }else{
      stat = "A";
    } 
    var qs = require('qs');
    var postObject = {
      admin_id : localStorage.getItem("admin_id"),
      id  :this.state.selectid,
      status: stat
    };

  axios.post(apiUrl+"category/change_status",qs.stringify(postObject)).then(res => {
    if(res.data.status === 'success'){
    
      this.listloadedData();
      $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message +'</h3></div>');
   
      setTimeout(
        function() {
       
          $('.success_message').html('');
           
        }
        .bind(this),
        3000
        );
    }
  }); 
}
  Confirmstatus =(confirmstatus)=>{
    console.log(this.state.selectedStatus,'confirmstatus')
    if(confirmstatus == 'yes'){
        if(this.state.selectedStatus=="Delete"){
          this.deletetab()
        }else if(this.state.selectedStatus=="Copy"){
          
          this.copycategory();
        } else{
          this.activatecategory();
        }
        $('.confirm-action').removeClass('show');
    }else{
      $('.confirm-action').removeClass('show');	
    }
  }
  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="Category" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
        <div className="car-listing-wrap leaa-admin-head card">
             <div className="success_message"></div>
              <div className="listing-header">
                <div className="title">
                  <h3>{this.state.totalRecord} Categories</h3>
                </div>

                 <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>

                 
                  <div className="add_button">  
                      <a className="add" href='/category-add'>Add</a> 
                  </div>
                
                
              </div>            
              {/*<div className="statusmessage"></div>*/}

              <div className="car-listing-row">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Category Name	</th>
                      <th> Image</th>
                      <th>Status</th>
                      <th>Priority</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.categorylist()}</tbody>
                </Table>
                 <div className="text-center">
           <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>
              </div>

            </div> 
        </div>  
      </div>
      <div className="confirm-action">
          <p>Are you sure! you want to {this.state.selectedStatus} the Category?</p>
          <a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
          <a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		  </div>  
    </div>
    );
  }
}




export default withRouter(List);