import React, { Component } from 'react';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import axios from 'axios';
import { apiUrl} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
import { useParams, useNavigate } from 'react-router-dom';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		
	  />
	);
  };
	
class Add extends Component {

	constructor(props)
	{
		// CheckAuth();
		super();	
		this.state = {
			title:'',
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'active'	,
			selectedVariantOption:'',
			catenamelist:[],	
			displayColorPicker: false,
			color: {
			r: '241',
			g: '112',
			b: '19',
			a: '1',
			}	
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);

    }

	componentDidMount() {
		var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl+'variants/getvariantnamelist?admin_id='+admin_id)
			.then(res => {
				if(res.data.status == 'success'){
				
					this.setState({
						catenamelist: res.data.catenamelist
					});
				
				}else{
				//console.log("test")
				}
			});
	}

	handleChange = (r,selOption) => {
       
		if(r == 'status'){
			this.setState({selectedOption:selOption})
			this.setState({  selectedvalue : selOption.value});
		}else if(r =='variant'){
			this.setState({selectedVariantOption:selOption})
			this.setState({  selectedvariantvalue : selOption.value});
		}
	};
		handleClick = () => {
			this.setState({displayColorPicker: !this.state.displayColorPicker })
			};
		handleClose = () => {
			this.setState({ displayColorPicker: false })
			};
   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });

  }
 
  
  handleSubmit = () => { 
	if(this.validateForm()){
	this.setState({Loading:true});
	const formdata = this.state;
	var qs = require('qs');
	var status = '';


	if(formdata.selectedvalue === ''){
	status =formdata.selectedOption.value;
	}else{
	status = formdata.selectedvalue;
	}

	var postObject ={
		admin_id: localStorage.getItem('admin_id'),
		name : formdata.title,
		status: status,
		color_code : formdata.hexColor,
		cate_id : formdata.selectedvariantvalue,
	}

	let formData = new FormData();
	for(let k in postObject) {
		formData.append(k, postObject[k]);
	}
	axios.post(apiUrl+"variants/add",formData).then(res => {
		if(res.data.status === "success"){
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						
						setTimeout(
							function() {
								$('.success_message').html(this.props.navigate('/variants'));
							}
							.bind(this),
							3000
							);
			}else{
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
				
				setTimeout(
				function() {
					$('.success_message').html('');
				}
				.bind(this),
				3000
				);
				this.setState({Loading:false});
			}
		});
		
	}
  }

  validateForm() {
	const {title} = this.state;

	let errors = {};
	  let formIsValid = true;

	  if(!title){
		formIsValid = false;
		$('.errortitle').html('<span class="errorspan">Please fill the field</span>');
	}else{
		
		$('.errortitle').html('');
	}
	if(errors>0) {
		
		return false;
		}
		else {
		return true;
		}
	
}


handleChangecolor = (color) => {
	this.setState({hexColor : color.hex})
	this.setState({ color: color.rgb })
	};

  render() {
	const {selectedOption,selectedVariantOption} = this.state;
	const navigate = this.props.navigate;
	const styles = reactCSS({
		'default': {
		  color: {
			width: '36px',
			height: '14px',
			borderRadius: '2px',
			background: `${ this.state.hexColor }`,
		  },
		  swatch: {
			padding: '5px',
			background: '#fff',
			borderRadius: '1px',
			boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
			display: 'inline-block',
			cursor: 'pointer',
		  },
		  popover: {
			position: 'absolute',
			zIndex: '2',
		  },
		  cover: {
			position: 'fixed',
			top: '0px',
			right: '0px',
			bottom: '0px',
			left: '0px',
		  },
		},
	  });
    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="Variants" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="content-wrapper">
					<a onClick={() => navigate(-1)}>Back</a>   
				</div>
			<div className="success_message"></div>
			<div className="title">
				<h4>Add variant </h4>				
			</div>
			 <form className="edit"  onSubmit={ e => { this.handleSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">					
					<label>Variant Category<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   name="category"
						   value={selectedVariantOption?selectedVariantOption:{ value: '', label: 'Select a variant category'}}
                            options={this.state.catenamelist} 
							isSearchable = {false}
							
							onChange={this.handleChange.bind(this, 'variant')}
                        />
	           </div> 
			  
			   {this.state.selectedVariantOption.label == 'Color' && (
			   <div className="form-group">					
					<div className="form-group site-bg-clr"> 
						<label>Variant Color : </label>
							<div style={ styles.swatch } onClick={ this.handleClick }>
							<div style={ styles.color } />
							</div>
							{ this.state.displayColorPicker ? <div style={ styles.popover }>
							<div style={ styles.cover } onClick={ this.handleClose }/>
							<SketchPicker color={ this.state.color } onChange={ this.handleChangecolor } />
							</div> : null }
							{this.state.hexColor && (
									<input type="text" name="hexColor" onChange={this.handleInputChange} className="form-control" value={this.state.hexColor}/>
								)}
							<></>
						</div>
				</div>
				 )}
			</div>
                  
			<div className="form-right">
			<div className="form-group">
					<label>Variant name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="title" onChange={this.handleInputChange} className="form-control" value={this.state.title}/>
					<div className="errortitle"></div>
				</div>
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   name="status"
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
							isSearchable = {false}
							onChange={this.handleChange.bind(this, 'status')}
                            />
	           </div> 
			  
             

			</div>	
			</div>		
			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>Adding</span>:<span>Submit</span>
                                        }
			   </button>
			 
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Add));