
import Header from '../Layouts/Header';
import React, { Component } from 'react';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl,categoryURL} from'../Config/Config';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
 

class Edit extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		const subscriptionid = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
	    
		this.state = {
			subscription_name:'',
			subscription_days:'',
			statusmessage:'',
			subscription_id: subscriptionid,
			Loading:false,
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			config: {
			extraAllowedContent: 'div(*)',
			allowedContent: true
			}
		};
		
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
       
		axios.get(apiUrl+"subscription/subscriptiondetail?id="+subscriptionid).then(res => {

			if(res.data.status === 'success'){  
						const formdata = res.data.subscriptionlist;

						if(formdata.status === 'A'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

						}
					
						this.setState({subscription_name:formdata.subscription_name});
						this.setState({subscription_days:formdata.subscription_days});
			} else {

			}
			
		});
    }
	


   handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };

   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

	componentDidMount() {
		document.title = PageTitle('Subscription Edit');

		
    }


     handleFormSubmit = () => {
		if(this.validateForm()){
			this.setState({Loading:true});
			const formPayload = this.state;
			const config = {
				headers: {
					'content-type': 'multipart/form-data'
				}
			}
			var qs = require('qs');
			var status = '';
			if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
					status =formPayload.selectedOption.value;
					}else{
					status = formPayload.selectedvalue;
					}
			var postObject = {
				admin_id : localStorage.getItem("admin_id"),
				subscription_id : this.state.subscription_id,
				subscription_name: formPayload.subscription_name,
				subscription_days:formPayload.subscription_days,
				status:  status,
			};

			

			axios.post(apiUrl+"subscription/edit",postObject,config).then(res => {

				if(res.data.status === "success"){

					const formdata = res.data.subscriptionedit;

    				if(formdata.status === 'A'){
					this.setState({selectedOption:{value: 'active', label: 'Active'}});
					}else{
					this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
	
					}
				
				this.setState({subscription_name:formdata.subscription_name});
				this.setState({subscription_days:formdata.subscription_days});
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');

								setTimeout(
								function() {
									this.props.navigate('/subscriptions');
									// $('.success_message').html(this.props.history.push('/color'));
								}
								.bind(this),
								3000
								);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}
			});
			
		}
	}

	validateForm() {
		const {subscription_name,subscription_days} = this.state;
		let errors = {};
      	let formIsValid = true;
	
		if (!subscription_name) {
			formIsValid = false;
			$('.errorsubscription_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(subscription_name){
			$('.errorsubscription_name').html('');
		}

		if (!subscription_days) {
			formIsValid = false;
			$('.errorsubscription_days').html('<span class="errorspan">Please fill the field</span>');
		}else if(subscription_days){
			$('.errorsubscription_days').html('');
		}

		this.setState({
			errors: errors
		});
		return formIsValid;
    }



  render() {

  	const {selectedOption} = this.state;
  	const navigate = this.props.navigate;
  return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="subscriptions" />  

	
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
		<div className="form-wrapper leaa-admin-head card">
		
			<div className="success_message"></div>
				<div className="back-btn-lux">
					<a onClick={() => navigate(-1)}>Back</a>    
				</div> 
			<div className="title">
				<h4>Edit Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			{/* <div className="form-row mail-template-sec">
			<div className="form-left mail-template-inner"> */}
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Template Name:</label>
				    <input type="text" name="subscription_name" onChange={this.handleInputChange} className="form-control" value={this.state.subscription_name} />
					<div className="errorsubscription_name"></div>
				</div>

				

				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
	           </div> 

				
			</div>
			<div className="form-left">
				

				<div className="form-group">
					<label>Template Name:</label>
				    <input type="text" name="subscription_days" onChange={this.handleInputChange} className="form-control" value={this.state.subscription_days} />
					<div className="errorsubscription_days"></div>
				</div>

				

				
			</div>	
			</div>		

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}



export default withRouter(Edit);